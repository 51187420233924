<div class="left">
    
    <h1>Financial News<br>that Matters</h1>

    <div class="item-wrapper">
        <h3>Get Started</h3>
        <a routerLink="/news">
            Try our flagship GPT Analyst for free
            <mat-icon>chevron_right</mat-icon>
        </a>
    </div>

    <div class="item-wrapper" *ngIf="!(isAuthenticated$ | async)">
        <h3>Sign-up</h3>
        <a routerLink="/login">
            Start creating your custom prompts
            <mat-icon>chevron_right</mat-icon>
        </a>
    </div>

    <div class="item-wrapper">
        <h3>Product</h3>
        <a routerLink="/product">
            Learn more about our features
            <mat-icon>chevron_right</mat-icon>
        </a>
    </div>

    <div class="item-wrapper">
        <h3>Consulting</h3>
        <a routerLink="/consulting">
            Work with us on your goals
            <mat-icon>chevron_right</mat-icon>
        </a>
    </div>

</div>
<div class="right">
    <img src="/assets/landing.png">
</div>
