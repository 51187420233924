<h1>Terms and Conditions</h1>

<p>Last Updated: 20/09/2024</p>

<h2>Introduction</h2>
<p>
  Welcome to LogicFlow AG, a Swiss-based company providing AI-driven financial analysis through our GPT Financial
  Analyst system (hereinafter referred to as the "Service"). By using our Service, you agree to comply with and be bound
  by the following Terms and Conditions (hereinafter referred to as the "Terms"). These Terms, governed by Swiss law,
  regulate your access to and use of the Service. If you do not agree to these Terms, you must refrain from using the
  Service.<br><br>
  For the purposes of these Terms:
</p>

<ul>
  <li>"Company" refers to LogicFlow AG, a Swiss-based entity with registered office at Butzenstrasse 130, CH-8041
    Zürich.</li>
  <li>"User" refers to any individual or entity using the Service.</li>
  <li>"Service" refers to the GPT Financial Analyst tool provided by LogicFlow AG.</li>
</ul>

<h2>Eligibility and User Responsibilities</h2>

<ul>
  <li>Be at least 18 years old or the legal age of majority in your jurisdiction.</li>
  <li>Provide accurate and complete registration details where required.</li>
  <li>Comply with all applicable financial regulations and legal requirements in your jurisdiction.</li>
</ul>

<p>
  You are responsible for any activities that occur under your account. You agree not to use the Service for unlawful
  purposes, including, but not limited to, fraud, money laundering, or violation of financial regulations.
</p>

<h2>Scope of Services</h2>

<p>
  The Service provides AI-generated insights, analysis, and information related to financial markets, digital assets,
  and general economic trends. It does not provide personalized financial advice, investment recommendations, or
  portfolio management services.
</p>

<p>The GPT Financial Analyst offers:</p>

<ul>
  <li>General market analysis based on historical and real-time data</li>
  <li>Information on potential financial trends and macroeconomic factors</li>
</ul>

<p>Limitations:</p>

<ul>
  <li>The Service is intended for informational purposes only. It does not substitute professional financial or
    investment advice.</li>
  <li>Users should consult a licensed financial advisor before making any financial decisions based on the information
    provided by the Service.</li>
  <li>The Company makes no guarantees regarding the accuracy, reliability, or timeliness of the information provided.
  </li>
</ul>

<h2>Use of the Services</h2>

<p>
  You may use the Service only in accordance with these Terms and for lawful purposes. You agree to:
</p>

<ul>
  <li>Use the Service as intended for financial analysis.</li>
  <li>Refrain from modifying, copying, or redistributing any output or content generated by the Service without written
    permission from the Company.</li>
  <li>Not engage in activities that disrupt the Service, including hacking, reverse engineering, or attempting to breach
    security measures.</li>
</ul>

<p>
  The Company reserves the right to monitor your use of the Service to ensure compliance with these Terms.
</p>

<h2>Fees and Payment</h2>

<p>
  The Service may be provided on a free or paid subscription basis, depending on the plan selected. All fees are listed
  on our website and are subject to change at the Company’s discretion. Payments must be made via the methods indicated
  on our website (credit card).
</p>

<p>Subscription Fees:</p>

<ul>
  <li>Subscriptions are charged in advance and renew automatically unless canceled before the renewal date.</li>
  <li>Users may cancel their subscription at any time, but no refunds will be provided for the remaining period of a
    subscription unless explicitly stated otherwise.</li>
</ul>

<p>Refunds may be provided in exceptional cases, as determined by the Company in its sole discretion.</p>

<h2>Data Privacy and Security</h2>

<p>
  We collect and process personal data in compliance with the Swiss Federal Data Protection Act (FADP) and, where
  applicable, the EU General Data Protection Regulation (GDPR).
</p>

<p>Data Collected:</p>

<ul>
  <li>Personal data (e.g., name, email) provided during registration.</li>
  <li>Data submitted by Users as part of a custom prompt (if any).</li>
  <li>Financial data submitted by Users (if any).</li>
  <li>Usage data (e.g., interaction with the Service, log files).</li>
</ul>

<p>Use of Data:</p>

<ul>
  <li>Personal data is used to provide and improve the Service, manage accounts, and communicate with Users.</li>
  <li>Data may be shared with third-party service providers for the purpose of enhancing the Service (e.g., hosting
    services, payment processors).</li>
</ul>

<p>Data Security:</p>

<ul>
  <li>We implement technical and organizational measures to protect User data from unauthorized access, alteration, or
    destruction.</li>
  <li>Users are responsible for safeguarding their account credentials.</li>
</ul>

<p>For more information, please refer to our Privacy Policy.</p>

<h2>Disclaimer and Limitation of Liability</h2>

<p>
  The Service is provided on an "as is" and "as available" basis without any warranties, express or implied, including
  but not limited to the accuracy, completeness, or timeliness of the financial analysis. The Company does not guarantee
  that the Service will be free of errors, uninterrupted, or secure.
</p>

<p>Limitation of Liability:</p>

<ul>
  <li>To the extent permitted by Swiss law, the Company shall not be liable for any direct, indirect, incidental,
    consequential, or special damages arising out of or related to your use of the Service.</li>
  <li>Users assume full responsibility for any decisions made based on the financial analysis provided by the Service.
  </li>
  <li>The Company’s total liability, in any event, shall not exceed the fees paid by the User for the use of the
    Service.</li>
</ul>

<h2>Intellectual Property</h2>

<p>
  All content, software, and intellectual property rights associated with the Service, including the GPT Financial
  Analyst system, belong to LogicFlow AG and its licensors. LogicFlow AG has the necessary licenses to operate the
  service. Users are granted a limited, non-exclusive, non-transferable license to use the Service solely for personal
  or internal business purposes.
</p>

<p>Restrictions:</p>

<ul>
  <li>Users may not copy, modify, distribute, sell, or lease any part of the Service.</li>
  <li>Reverse engineering or attempting to extract the source code is strictly prohibited.</li>
</ul>

<h2>Amendments to the Terms and Conditions</h2>

<p>
  The Company reserves the right to update or modify these Terms at any time. When significant changes are made, the
  Company will notify Users by email or through the Service. Continued use of the Service after such changes signifies
  acceptance of the new Terms.
</p>

<p>Users may opt to discontinue the Service if they do not agree with the updated Terms.</p>

<h2>Termination</h2>

<p>
  The Company may terminate or suspend your access to the Service for violating these Terms or for any other reason, at
  its sole discretion.
</p>

<p>Upon termination:</p>

<ul>
  <li>Users will no longer have access to their accounts or the Service.</li>
  <li>Any data stored on the Service will be deleted, subject to applicable laws and the Company’s data retention
    policies.</li>
</ul>

<h2>Governing Law and Jurisdiction</h2>

<p>
  These Terms are governed by the laws of Switzerland, without regard to its conflict of law principles. Any disputes
  arising from or related to these Terms shall be subject to the exclusive jurisdiction of the courts located in Zurich,
  Switzerland.
</p>

<h2>Dispute Resolution</h2>

<p>
  In the event of a dispute, Users agree to first contact the Company to seek resolution informally.
</p>

<h2>Force Majeure</h2>

<p>
  The Company shall not be held liable for any delay or failure to perform its obligations under these Terms due to
  events beyond its reasonable control, including natural disasters, war, strikes, or interruptions of technical
  infrastructure.
</p>

<h2>Severability</h2>

<p>
  If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full
  force and effect.
</p>

<h2>Contact Information</h2>

<p>
  For any questions or concerns regarding these Terms, please contact us at:
</p>

<ul>
  <li>Email: info&#64;logicflow.ai</li>
  <li>Address: LogicFlow AG, Butzenstrasse 130, 8041 Zürich</li>
</ul>
