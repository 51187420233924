<mat-form-field class="full-width">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Filter by symbol" #input>
</mat-form-field>

<mat-table [dataSource]="dataSource" matSort matSortActive="started_at" matSortDirection="desc">
  <ng-container matColumnDef="symbol">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Symbol</mat-header-cell>
    <mat-cell *matCellDef="let item"><a routerLink="/ticker/{{ item.symbol }}">{{ item.symbol }}</a></mat-cell>
  </ng-container>

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
    <mat-cell *matCellDef="let item">{{ item.name }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
    <mat-cell *matCellDef="let item">{{ item.status }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="ownerId">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Owner</mat-header-cell>
    <mat-cell *matCellDef="let item">{{ item.ownerId }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="runFreq">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Run Frequency</mat-header-cell>
    <mat-cell *matCellDef="let item">{{ item.runFreq }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="started_at">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Started At</mat-header-cell>
    <mat-cell *matCellDef="let item">{{ item.started_at }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="cost">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Cost</mat-header-cell>
    <mat-cell *matCellDef="let item">{{ item.cost }}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
