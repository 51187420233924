import { Injectable, Optional } from '@angular/core';
import { BehaviorSubject, map, Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ref, objectVal, Database } from '@angular/fire/database';
import {
  Firestore,
  doc,
  collection,
  query,
  where,
  getDoc,
  getDocs,
  orderBy,
  limit,
  Timestamp,
} from '@angular/fire/firestore';
import { PromptsService } from './prompts.service';
import { RunResult } from '../model/run.model';

type FullReturnData = {
  [key: string]:  {
    execution_date: Timestamp
    execution_value: number
    prediction_date: Timestamp
    returns: number
  }
}

type RunsByPromptId = {
  [key: string]:  RunResult
}

@Injectable({
  providedIn: 'root'
})
export class RunService {
  public dateMap: Map<number, Map<string, Date | null>> = new Map();
  public runsByPromptId: RunsByPromptId = {};

  private runsByPromptIdSubject = new BehaviorSubject<RunsByPromptId>({});
  runsByPromptId$: Observable<RunsByPromptId> = this.runsByPromptIdSubject.asObservable();

  private promptPositionsSubject = new BehaviorSubject<any[]>([]);
  promptPositions$: Observable<any> = this.promptPositionsSubject.asObservable();

  private promptPerformanceSubject = new BehaviorSubject<any[]>([]);
  promptPerformance$: Observable<any> = this.promptPerformanceSubject.asObservable();

  backtestErrors$: Subscription = new Subscription();
  backtestErrors: any = {};

  constructor(
    private db: Firestore,
    private database: Database,
    private firestore: Firestore,
    private promptsService: PromptsService,
    @Optional() private toastr?: ToastrService) {

    this.registerBacktestListener();
  }

  getRun$(promptId: string | undefined): Observable<RunResult | undefined> {
    return this.runsByPromptId$.pipe(
      map((runs) => promptId ? runs[promptId] : undefined)
    );
  }

  load(prompt: any, setPositions: boolean = false) {
    const promptId = prompt.promptId;

    // If the run is already loaded, just set it
    if (promptId in this.runsByPromptId) {
      const run = this.runsByPromptId[promptId] as any;

      if (setPositions) {
        this.setPerformance(run?.performance?.returns);
        this.setPositions(run, setPositions);
      }

      return;
    }

    // If the prompt has no template id, log an error
    if (!prompt.full_template_id) {
      console.log('Skip loading run without template id:', promptId);
      return;
    }

    // Build the query to get the most recent run for the prompt
    // The same prompt can have multiple versions of the same template
    // so we need to get the most recent one
    const runsRef = collection(this.db, "runs");
    let constraints = [
      where("prompt_id", "==", promptId),
      where("template_id", "==", prompt.full_template_id),
      orderBy('created_at', 'desc'),
      limit(1)
    ];

    // Build the query
    const q = query(
      runsRef,
      ...constraints
    );

    // Execute the query to get the most recent run
    getDocs(q).then(async (snapshot) => {

      // If no run is found, set an empty run
      if (snapshot.docs.length === 0) {
        console.log('No run found for prompt', promptId);
        this.setPositions({}, setPositions);
        return;
      }

      const runDoc = snapshot.docs[0];
      const run: any = {
        runId: runDoc.id,
        ...runDoc.data()
      };

      run.full_predictions = [...run.predictions];
      this.setBacktestErrors(run);
      this.runsByPromptId[run.prompt_id] = run;
      this.runsByPromptIdSubject.next(this.runsByPromptId);
      this.loadReturns(run, setPositions).finally(() => {
        this.setPositions(run, setPositions);
      });

    }).catch((error) => {
      console.error('Error loading prompt run:', error);
      this.toastr?.error(error, 'Error loading prompt run');
    });
  }

  clear() {
    this.promptPositionsSubject.next([]);
    this.promptPerformanceSubject.next([]);
  }

  /**
   * Load the run returns
   * @param run The run object
   */
  private loadReturns(run: any, setPositions: boolean): Promise<void> {
    const runId = run.runId;

    // If the run has no returns, set an empty performance
    if (!runId) {
      if(setPositions) this.promptPerformanceSubject.next([]);
      return Promise.resolve();
    }

    return getDoc(
      doc(this.firestore, 'runs', runId, 'series', 'returns')
    ).then((p: any) => {

      // Set the returns in the run object
      const returns = p.data() as FullReturnData;
      if ("performance" in run) {
        run["performance"] = {
          ...run["performance"],
          returns: returns
        };
      }

      // Set the performance
      if (setPositions) {
        this.setPerformance(returns);
      }

    }).catch((error) => {
      console.error('Error loading run returns:', error);
      this.toastr?.error(error, 'Error loading run returns');
    });
  }

  /**
   * Set the backtest errors for a prompt
   */
  private setBacktestErrors(run: any): void {
    let backtestErrors = {};
    if (run.runId in this.backtestErrors) {
      backtestErrors = this.backtestErrors[run.runId] ?? {};
    }

    this.promptsService.updatePrompts({
      [run.prompt_id]: {
        errors: backtestErrors
      }
    });
  }

  /**
   * Set the performance
   */
  private setPerformance(returns: any): void {
    // If there are no returns, set an empty performance
    if (!returns) {
      this.promptPerformanceSubject.next([]);
      return;
    }

    // Compute the price sequence and set the performance
    this.computePriceSequence(returns).then((prices) => {
      this.promptPerformanceSubject.next(prices);
    });
  }

  /**
   * Set the positions for the run
   */
  private setPositions(result: any, setPositions: boolean = false): void {
    if (!setPositions) return;

    // Remove predictions which do not have a valid execution date
    let validDates: (number | undefined)[] = [];
    for (let [_, v] of this.dateMap) {
      validDates.push(v.get("predictionDate")?.getTime())
    };

    // Sort the predictions by date and filter out invalid dates
    result.predictions = result.full_predictions
      ?.sort((a: any, b: any) => {
        return a.prediction_date <= b.prediction_date ? -1 : 1;
      })
      .filter((a: any) => {
        return validDates.includes(new Date(a.prediction_date).getTime())
      }) ?? [];

    this.promptPositionsSubject.next(result.predictions);
  }

  /**
   * Set the date map for the run
   */
  private setDateMap(r: any, isExecuted: boolean = true) {
    const key = isExecuted ? "execution_date" : "prediction_date";
    this.dateMap.set(
      r[key].toDate().getTime(),
      new Map(Object.entries({
        executionDate: isExecuted ? r["execution_date"].toDate() : null,
        predictionDate: r["prediction_date"].toDate()
      }))
    );
  }

  private async computePriceSequence(returns: FullReturnData): Promise<{ x: Date, y: number }[]> {
    // Sort the dates
    const sortedDates = Object
      .keys(returns)
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    let prices: {x: Date, y: number}[] = [];
    let returnsStartIdx: number = 0;

    // Handle new-type returns here which should greatly simplify the process
    returnsStartIdx = sortedDates.findIndex((date) => returns[date]["execution_date"] != null);

    const firstReturn = returns[sortedDates[returnsStartIdx]];
    this.setDateMap(firstReturn);
    prices[0] = {
      x: firstReturn["execution_date"].toDate(),
      y: firstReturn["execution_value"]
    };

    // Calculate the price for each date
    for (let i = returnsStartIdx + 1; i < sortedDates.length; i++) {
      const isLatest = i == sortedDates.length - 1;
      const r = returns[sortedDates[i]];

      // Predictions without execution date are hidden
      // Only the latest prediction is shown
      // because the execution date is not known
      if (r["execution_date"] === null && !isLatest) {
        continue;
      };

      const prevPrice = prices[prices.length - 1].y;
      const newPrice = prevPrice * (1 + r["returns"]);
      const dateKey = isLatest ? "prediction_date" : "execution_date";
      this.setDateMap(r, !isLatest);
      prices.push({
        x: r[dateKey].toDate(),
        y: newPrice
      });
    }

    return prices;
  }

  /**
   * Register a listener for backtest errors
   */
  private registerBacktestListener() {
    if (!this.database) return;

    // Unsubscribe from the previous listener
    this.backtestErrors$.unsubscribe();

    // Subscribe to the backtest errors
    this.backtestErrors$ = objectVal(
      ref(this.database, '/backtests/errors')
    ).subscribe((data: any) => {
      this.backtestErrors = {
        ...this.backtestErrors,
        ...data
      };
    });
  }
}
