import { State, Action, StateContext, Store } from '@ngxs/store';
import { Injectable, OnDestroy, Optional } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Prompt } from '../../model/prompt.model';
import { Firestore, collection, query, where, doc, deleteDoc, updateDoc, setDoc, onSnapshot } from '@angular/fire/firestore';
import { UserState } from '../user/user.state';

// Actions
export class LoadPrompts {
  static readonly type = '[Prompts] Load Symbol';
  constructor(public symbol: string) {}
}

export class LoadUserPrompts {
  static readonly type = '[Prompts] Load User';
  constructor() {}
}

export class SavePrompt {
  static readonly type = '[Prompts] Save';
  constructor(public prompt: Prompt) {}
}

export class UpdatePrompt {
  static readonly type = '[Prompts] Update';
  constructor(public prompt: Prompt) {}
}

export class DeletePrompt {
  static readonly type = '[Prompts] Delete';
  constructor(public promptId: string) {}
}

export class ToggleActiveStatus {
  static readonly type = '[Prompts] Toggle Active';
  constructor(public promptId: string) {}
}

export class ToggleTradedStatus {
  static readonly type = '[Prompts] Toggle Traded';
  constructor(public promptId: string) {}
}

export interface PromptsStateModel {
  prompts: Prompt[];
}

@State<PromptsStateModel>({
  name: 'prompts',
  defaults: {
    prompts: []
  }
})
@Injectable()
export class PromptsState implements OnDestroy {
  symbolPromptsUnsubscribe: any;
  userPromptsUnsubscribe: any;

  constructor(
    private store: Store,
    private db: Firestore,
    @Optional() private toastr?: ToastrService)
  {}

  ngOnDestroy(): void {
    this.symbolPromptsUnsubscribe?.();
    this.userPromptsUnsubscribe?.();
  }

  /**
   * Load prompts for a given symbol.
   * @param getState
   * @param patchState
   * @param symbol
   * @returns Promise<Prompt[]>
  */
  @Action(LoadPrompts)
  loadPrompts({ getState, patchState }: StateContext<PromptsStateModel>, { symbol }: LoadPrompts): Promise<Prompt[]> {
    const collectionRef = collection(this.db, 'prompts');
    const constraints = [where('target_ticker', '==', symbol)];
    const q = query(collectionRef, ...constraints);

    this.symbolPromptsUnsubscribe?.();
    return new Promise((resolve, reject) => {
      this.symbolPromptsUnsubscribe = onSnapshot(q, (snapshot) => {
        let prompts: any[] = this.snapshotToPrompts(snapshot);
        patchState({
          prompts: this.mergePrompts(getState, prompts)
        });
        resolve(prompts);
      }, (error: any) => {
        this.toastr?.error(error.message, 'Error loading prompts');
        reject(error);
      });
    });
  }

  /**
   * Load prompts for the current user.
   * @param param0
   * @returns Promise<Prompt[]>
   */
  @Action(LoadUserPrompts)
  loadUserPrompts({ getState, patchState }: StateContext<PromptsStateModel>): Promise<Prompt[]> {
    const collectionRef = collection(this.db, 'prompts');
    const constraints = [where('owner_id', '==', this.getUserId())];
    const q = query(collectionRef, ...constraints);

    this.userPromptsUnsubscribe?.();
    return new Promise((resolve, reject) => {
      this.userPromptsUnsubscribe = onSnapshot(q, (snapshot) => {
        let prompts: any[] = this.snapshotToPrompts(snapshot);
        patchState({
          prompts: this.mergePrompts(getState, prompts)
        });
        resolve(prompts);
      }, (error) => {
        if (this.toastr) this.toastr.error(error.message, 'Error syncing user prompts');
          reject(error);
      });
    });
  }

  private snapshotToPrompts(snapshot: any): Prompt[] {
    const userId = this.getUserId();
    let prompts: any[] = [];
    snapshot.forEach((doc: any) => {
      const data: any = doc.data();
      const prompt = {
        ...data,
        promptId: doc.id,
        backtestStatus: data.backtest_count > 0 ? 'complete' : 'untested',
        isOwner: data.owner_id === userId
      };
      prompts.push(prompt);
    });
    return prompts;
  }

  @Action(SavePrompt)
  async savePrompt({ getState, patchState }: StateContext<PromptsStateModel>, { prompt }: SavePrompt) {
    const docRef = doc(this.db, `prompts/${prompt.promptId}`);

    try {
      await setDoc(docRef, { ...prompt, owner_id: this.getUserId() });
      patchState({
        prompts: this.mergePrompts(getState, [prompt])
      });
      this.toastr?.success('Prompt saved successfully', 'Success', { disableTimeOut: false });
    } catch (error: any) {
      this.toastr?.error(error.message, 'Error saving prompt');
    }
  }

  private mergePrompts(getState: () => PromptsStateModel, prompts: Prompt[]) {
    // Get existing prompts that are not in the new list
    const existingPrompts = getState().prompts.filter(p1 => !prompts.some(p2 => p2.promptId === p1.promptId));

    // Merge
    const allPrompts = [...existingPrompts, ...prompts];

    // Sort prompts by name
    allPrompts.sort((a, b) => a.name.localeCompare(b.name));

    // Return
    return allPrompts;
  }

  @Action(UpdatePrompt)
  async updatePrompt({ getState, patchState }: StateContext<PromptsStateModel>, { prompt }: UpdatePrompt) {
    const docRef = doc(this.db, `prompts/${prompt.promptId}`);

    try {
      const existingPrompt = getState().prompts.find(p => p.promptId === prompt.promptId);
      if (existingPrompt?.owner_id === this.getUserId()) {
        await updateDoc(docRef, prompt as any);
        patchState({
          prompts: getState().prompts.map(p => p.promptId === prompt.promptId ? prompt : p)
        });
        this.toastr?.success('Prompt updated successfully', 'Success', { disableTimeOut: false });
      } else {
        throw new Error('You are not the owner of this prompt');
      }
    } catch (error: any) {
      this.toastr?.error(error.message, 'Error updating prompt');
    }
  }

  @Action(DeletePrompt)
  async deletePrompt({ getState, patchState }: StateContext<PromptsStateModel>, { promptId }: DeletePrompt) {
    const docRef = doc(this.db, `prompts/${promptId}`);

    try {
      const existingPrompt = getState().prompts.find(p => p.promptId === promptId);
      if (existingPrompt?.owner_id === this.getUserId()) {
        await deleteDoc(docRef);
        patchState({
          prompts: getState().prompts.filter(p => p.promptId !== promptId)
        });
        this.toastr?.success('Prompt deleted successfully', 'Success', { disableTimeOut: false });
      } else {
        throw new Error('You are not the owner of this prompt');
      }
    } catch (error: any) {
      this.toastr?.error(error.message, 'Error deleting prompt');
    }
  }

  @Action(ToggleActiveStatus)
  async toggleActiveStatus({ getState, patchState }: StateContext<PromptsStateModel>, { promptId }: ToggleActiveStatus) {
    const docRef = doc(this.db, `prompts/${promptId}`);

    try {
      const existingPrompt = getState().prompts.find(p => p.promptId === promptId);
      if (existingPrompt?.owner_id === this.getUserId()) {
        const updatedPrompt = { ...existingPrompt, active: !existingPrompt.active };
        await updateDoc(docRef, { active: updatedPrompt.active });
        patchState({
          prompts: getState().prompts.map(p => p.promptId === promptId ? updatedPrompt : p)
        });
        this.toastr?.success('Prompt status toggled successfully', 'Success', { disableTimeOut: false });
      } else {
        throw new Error('You are not the owner of this prompt');
      }
    } catch (error: any) {
      this.toastr?.error(error.message, 'Error toggling prompt status');
    }
  }

  @Action(ToggleTradedStatus)
  async toggleTradedStatus(
    { getState, patchState }: StateContext<PromptsStateModel>,
    { promptId }: ToggleActiveStatus) {

    const userId = this.getUserId();
    const docRef = doc(this.db, `prompts/${promptId}`);

    try {
      const existingPrompt = getState().prompts.find(p => p.promptId === promptId);
      if (existingPrompt?.owner_id === userId) {
        const updatedPrompt = { ...existingPrompt, trade_on_decision: !(existingPrompt.trade_on_decision ?? false)};
        await updateDoc(docRef, { trade_on_decision: updatedPrompt.trade_on_decision });
        patchState({
          prompts: getState().prompts.map(p => p.promptId === promptId ? updatedPrompt : p)
        });
        this.toastr?.success('Trading status toggled successfully', 'Success', { disableTimeOut: false });
      } else {
        throw new Error('You are not the owner of this prompt');
      }
    } catch (error: any) {
      this.toastr?.error(error.message, 'Error toggling trading status');
    }
  }

  private getUserId() {
    return this.store.selectSnapshot(UserState.getUserId) || '';
  }
}
