import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ga-autcomplete-dialog',
  template: `
    <h1 mat-dialog-title>Configure Data Item</h1>
    <div mat-dialog-content>
      <p>You selected the data item: {{ data.name }}</p>
      <!-- Add custom configuration options here -->
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-button [mat-dialog-close]="true">Save</button>
    </div>
  `
})
export class GaAutocompleteDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<GaAutocompleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string })
  {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
