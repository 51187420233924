<div class="user-prompts-container">
  <h2>My Prompts</h2>
  <table mat-table [dataSource]="dataSource" matSort>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let prompt" (click)="viewPrompt(prompt)">
        {{ prompt.name }}
      </mat-cell>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="symbol">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Symbol</mat-header-cell>
      <mat-cell *matCellDef="let prompt">
        {{ prompt.target_ticker }}
      </mat-cell>
    </ng-container>

    <!-- AR Column -->
    <ng-container matColumnDef="ar">
      <mat-header-cell *matHeaderCellDef mat-sort-header title="Annualized return">AR</mat-header-cell>
      <mat-cell *matCellDef="let prompt">
        {{ prompt.annualized_return ? (100 * prompt.annualized_return).toFixed(1) + '%' : 'N/A' }}
      </mat-cell>
    </ng-container>

    <!-- AR+ Column -->
    <ng-container matColumnDef="are">
      <mat-header-cell *matHeaderCellDef mat-sort-header title="Annualized return excess">AR+</mat-header-cell>
      <mat-cell *matCellDef="let prompt">
        {{ prompt.annualized_excess_return ? (100 * prompt.annualized_excess_return).toFixed(1) + '%' : 'N/A' }}
      </mat-cell>
    </ng-container>

    <!-- Active Column -->
    <ng-container matColumnDef="active">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Active</mat-header-cell>
      <mat-cell *matCellDef="let prompt">
        <button
          mat-icon-button
          (click)="toggleActiveStatus(prompt)"
          [disabled]="!prompt.isOwner"
        >
          <mat-icon>{{ prompt.active ? 'toggle_on' : 'toggle_off' }}</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let prompt">
        <button
          mat-icon-button
          title="Delete"
          (click)="deletePrompt(prompt)"
          [disabled]="!prompt.isOwner"
        >
          <mat-icon>delete</mat-icon>
        </button>
        <button
          mat-icon-button
          title="Trade"
          (click)="toggleTradedStatus(prompt)"
          [disabled]="!(prompt.isOwner && prompt.active)"
        >
          <img
            *ngIf="prompt.trade_on_decision"
            src="../../../assets/alpaca-logo.svg"
            height="100ph"
          >
          <img
            *ngIf="!prompt.trade_on_decision"
            src="../../../assets/alpaca-logo-disabled.svg"
            height="100ph"
          >
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>
</div>
