import { Injectable, OnDestroy, Optional } from '@angular/core';
import { SymbolService } from './symbol.service';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  takeUntil,
} from 'rxjs';
import { ref, objectVal, Database } from '@angular/fire/database';
import { collection, query, where, onSnapshot, Unsubscribe, Firestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { Prompt } from '../model/prompt.model';
import { Store } from '@ngxs/store';
import { UserState } from '../store/user/user.state';

@Injectable({
  providedIn: 'root',
})
export class PromptsService implements OnDestroy {
  private snapshotListener: Unsubscribe | undefined;
  private destroy$ = new Subject<void>();

  promptsSubject: BehaviorSubject<Prompt[]> = new BehaviorSubject<Prompt[]>([]);
  prompts$: Observable<Prompt[]> = this.promptsSubject.asObservable();

  backtests$: Subscription = new Subscription();

  constructor(
    private store: Store,
    private db: Firestore,
    private database: Database,
    private symbolService: SymbolService,
    @Optional() private toastr?: ToastrService) {

    this.symbolService.symbol$
      .pipe(takeUntil(this.destroy$))
      .subscribe((symbol) => {
        this.load(symbol);
      });

    this.store.select(UserState.getUserId).subscribe((userId) => {
      let prompts = this.promptsSubject.value;
      prompts = prompts.map((prompt: any) => {
        prompt.isOwner = prompt.owner_id === userId;
        return prompt;
      });
      this.promptsSubject.next(prompts);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  remove(promptId: string) {
    let prompts = this.promptsSubject.value;
    prompts = prompts.filter((prompt: any) => prompt.promptId !== promptId);
    this.promptsSubject.next(prompts);
  }

  load(symbol: string) {
    if (!symbol) return;

    const promptsRef = collection(this.db, "prompts");
    const q = query(promptsRef, where("target_ticker", "==", symbol));
    this.snapshotListener?.();
    this.snapshotListener = onSnapshot(q, (snapshot) => {
      const userId = this.store.selectSnapshot(UserState.getUserId);
      let prompts: any[] = [];
      snapshot.forEach((doc) => {
        const data: any = doc.data();
        const prompt = {
          ...data,
          promptId: doc.id,
          backtestStatus: data.backtest_count > 0 ? 'complete' : 'untested',
          isOwner: data.owner_id === userId
        };
        prompts.push(prompt);
      });

      this.promptsSubject.next(prompts);
      this.registerBacktestListener(symbol);
    }, (error) => {
      this.toastr?.error(`${error}`, 'Error loading prompts');
    });
  }

  setActive(promptId: string, active: boolean) {
    const update = {
      [promptId]: {
        active,
      },
    };

    this.updatePrompts(update);
  }

  initializeBacktest(run: any) {
    // console.log('Initializing backtest with run:', run);
    this.updateBacktestStatus({
      [run.promptId]: {
        status: 'queued',
        success: 0,
        failed: 0,
        total: run.runConfig.backtestConfig.evalCount
      },
    });
  }

  failBacktest(promptId: string) {
    this.updateBacktestStatus({
      [promptId]: {
        status: 'failed',
        success: 0,
        failed: 0,
        total: 0
      },
    });
  }

  updateBacktestStatus(updates: any) {
    // console.log('Updating backtest status:', updates);
    updates = updates || {};

    function progress(completed: number, total: number) {
      try {
        return (100 * completed) / total;
      } catch (error) {
        return 0;
      }
    }

    for (const promptId in updates) {
      const backtest = updates[promptId];
      const completed = backtest.success + backtest.failed;

      updates[promptId] = {
        backtestStatus: backtest.status,
        completed: completed,
        success: backtest.success,
        failed: backtest.failed,
        total: backtest.total,
        errors: backtest.errors ?? {},
        progress: progress(completed, backtest.total)
      };
    }

    this.updatePrompts(updates);
  }

  updatePrompts(updates: any) {
    let prompts = this.promptsSubject.value;

    prompts = prompts.map((prompt: Prompt) => {
      if (!prompt.promptId) return prompt;

      const update = updates[prompt.promptId] || {};
      return {
        ...prompt,
        ...update,
      } as Prompt;
    });

    // console.log('Updated prompts:', updates);
    this.promptsSubject.next(prompts);
  }

  private registerBacktestListener(symbol: string) {
    this.backtests$.unsubscribe();
    this.backtests$ = objectVal(
      ref(this.database, '/backtests/ticker/' + symbol)
    ).subscribe((data: any) => {
      this.updateBacktestStatus({...data});
    });
  }
}
