import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ga-autocomplete-dropdown',
  template: `
    <div [ngStyle]="{'position': 'absolute', 'top.px': position.top, 'left.px': position.left}" class="dropdown">
      <mat-list>
        <mat-list-item *ngFor="let option of options" (click)="selectOption(option)">
          {{ option }}
        </mat-list-item>
      </mat-list>
    </div>
  `,
  styleUrls: ['./ga-autocomplete-dropdown.component.scss']
})
export class GaAutocompleteDropdownComponent {
  @Input() options: string[] = [];
  @Input() position: { top: number; left: number } = { top: 0, left: 0 };
  @Output() optionSelected = new EventEmitter<string>();

  selectOption(option: string) {
    this.optionSelected.emit(option);
  }
}
