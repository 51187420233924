import { State, Action, StateContext } from '@ngxs/store';
import { Injectable, OnDestroy, Optional } from '@angular/core';
import { Firestore, query, where, collection, onSnapshot } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { Prediction } from './prediction.model';

// Actions
export class LoadPredictionsForGenerator {
  static readonly type = '[Prediction] Load By Generator';
  constructor(public ids: string[]) {}
}

export class PredictionBack {
  static readonly type = '[Prediction] Navigate Backward';
  constructor(public id: string) {}
}

export class PredictionNext {
  static readonly type = '[Prediction] Navigate Forward';
  constructor(public id: string) {}
}

export interface PredictionStateModel {
  predictionsByGenerator: { [key: string]: Prediction[] };
  selectedIndex: { [key: string]: number };
}

@State<PredictionStateModel>({
  name: 'predictions',
  defaults: {
    predictionsByGenerator: {},
    selectedIndex: {}
  }
})
@Injectable()
export class PredictionState implements OnDestroy {
  private predictionUnsubscribe: any;

  constructor(
    private firestore: Firestore,
    @Optional() private toastr?: ToastrService)
  {}

  ngOnDestroy(): void {}

  @Action(LoadPredictionsForGenerator)
  loadPredictionsByNewsSummary(
    { getState, patchState }: StateContext<PredictionStateModel>,
    { ids }: LoadPredictionsForGenerator): Promise<Prediction[]> {

    if (!ids || ids.length === 0) {
      return Promise.resolve([]);
    }

    const q = query(
      collection(this.firestore, 'predictions'),
      where('news_summariser_id', 'in', ids)
    );

    this.predictionUnsubscribe?.();
    return new Promise((resolve, reject) => {
      this.predictionUnsubscribe = onSnapshot(q, (snapshot) => {
        const allPredictions: Prediction[] = snapshot.docs.map(doc => {
          const data: any = doc.data();
          return {
            ...data,
            prediction_id: doc.id,
            valid_at: data.valid_at.toDate()
          } as Prediction;
        });

        const predictionsByGenerator: { [key: string]: Prediction[] } = {
          ...getState().predictionsByGenerator
        };
        const selectedIndex: { [key: string]: number } = {
          ...getState().selectedIndex
        };

        ids.forEach(id => {
          const predictions = allPredictions.filter(p => p.news_summariser_id === id);
          predictions.sort((a, b) => a.valid_at.getTime() - b.valid_at.getTime());
          predictionsByGenerator[id] = predictions;

          if (!selectedIndex[id] || selectedIndex[id] >= predictions.length - 2) {
            selectedIndex[id] = predictions.length - 1;
          }
        });

        patchState({
          predictionsByGenerator,
          selectedIndex
        });

        resolve(Object.values(predictionsByGenerator).flat());
      }, (error) => {
        this.toastr?.error(error.message, 'Error loading predictions');
        reject(error);
      });
    });
  }

  @Action(PredictionBack)
  navigateBackward(
    { getState, patchState }: StateContext<PredictionStateModel>,
    { id }: PredictionBack) {

    const state = getState();
    const selectedIndex = state.selectedIndex[id] || 0;
    if (selectedIndex > 0) {
      patchState({
        selectedIndex: {
          ...state.selectedIndex,
          [id]: selectedIndex - 1
        }
      });
    }
  }

  @Action(PredictionNext)
  navigateForward(
    { getState, patchState }: StateContext<PredictionStateModel>,
    { id }: PredictionNext) {

    const state = getState();
    const n = state.predictionsByGenerator[id]?.length || 0;
    const selectedIndex = state.selectedIndex[id] || 0;
    if (selectedIndex < n - 1) {
      patchState({
        selectedIndex: {
          ...state.selectedIndex,
          [id]: selectedIndex + 1
        }
      });
    }
  }
}
