import {
  Component,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, takeUntil } from 'rxjs';
import { SymbolService } from '../../../services/symbol.service';
import { DataItemState, LoadDataItems } from '../../../store/data-item/data-item.state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-prompt-inputs',
  templateUrl: './prompt-inputs.component.html',
  styleUrl: './prompt-inputs.component.scss',
})
export class PromptInputsComponent implements AfterViewInit, OnDestroy {
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = [
    'name',
    'description',
    'backtestable',
    'actions',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  @Output() onInsert = new EventEmitter<any>();
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private symbolService: SymbolService)
  {}

  ngAfterViewInit() {
    this.symbolService.symbol$
      .pipe(takeUntil(this.destroy$))
      .subscribe((symbol) => {
        if (!symbol) return;

        // Dispatch the action to load data items from the store
        this.store.dispatch(new LoadDataItems(symbol));

        // Subscribe to the selector to update the table when the data items for the symbol are loaded
        this.store.select(DataItemState.getDataItemsBySymbol(symbol))
          .pipe(takeUntil(this.destroy$))
          .subscribe((items) => {
            this.dataSource = new MatTableDataSource<any>(items);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
