import { Component, OnInit } from '@angular/core';
import { collection, Firestore, getDocs, limit, query, where } from '@angular/fire/firestore';
import { FormControl } from '@angular/forms';
import { orderBy } from 'firebase/firestore';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexMarkers,
  ApexXAxis,
  ApexYAxis,
  ApexTooltip,
  ApexTitleSubtitle,
  ApexAnnotations
} from 'ng-apexcharts';

type ChartDataPoint = { x: number; y: number; fillColor: string };

@Component({
  selector: 'app-prompt-performance',
  templateUrl: './prompt-performance.component.html',
  styleUrl: './prompt-performance.component.scss'
})
export class PromptPerformanceComponent implements OnInit {
  public startDate = new FormControl<Date>(new Date());
  public series: ApexAxisChartSeries = [];

  public tooltip: ApexTooltip = {
    enabled: true,
    custom: ({ _, seriesIndex, dataPointIndex, w }) => {
      const data = w.config.series[seriesIndex].data[dataPointIndex];
      return `<div class="chart-tooltip">
                <span>Return: ${data.x.toFixed(1)}</span><br>
                <span>Excess Return: ${data.y.toFixed(1)}</span><br>
                <span>Title: ${data.title}</span><br>
                <span>Prompt Name: ${data.prompt_name}</span><br>
                <span>Sector: ${data.sector}</span>
              </div>`;
    }
  };

  public chart: ApexChart = {
    type: 'scatter',
    height: 480,
    zoom: { enabled: true },
    toolbar: { show: true },
    events: {
      markerClick: (_, __, { seriesIndex, dataPointIndex }) => {
        const data: any = this.series[seriesIndex].data[dataPointIndex];
        console.log(data);
        const url = `/ticker/${data.ticker}/${data.prompt_id}`;
        window.open(url, '_blank');
      }
    }
  };

  public xaxis: ApexXAxis = {
    type: 'numeric',
    min: -30,
    max: 100,
    title: { text: 'Annualized Return' },
    labels: {
      formatter: function(val) {
        return parseFloat(val).toFixed(1); // Formats labels to one decimal place
      }
    },
    tickAmount: 10, // Limits the number of ticks to 10
    axisTicks: {
      show: true
    }
  };

  public yaxis: ApexYAxis = {
    title: { text: 'Annualized Excess Return' },
    min: -60,
    max: 60,
    labels: {
      formatter: function(val) {
        return val.toFixed(1); // Formats labels to one decimal place
      }
    },
    tickAmount: 10, // Limits the number of ticks to 10
    axisTicks: {
      show: true
    }
  };

  public markers: ApexMarkers = {
    size: 5
  };

  public dataLabels: ApexDataLabels = {
    enabled: false
  };

  public title: ApexTitleSubtitle = {
    text: 'Performance Distribution',
  };

  public annotations: ApexAnnotations = {
    yaxis: [
      {
        y: 0,
        borderColor: 'black',
        borderWidth: 2
      }
    ],
    xaxis: [
      {
        x: 0,
        borderColor: 'black',
        borderWidth: 2
      }
    ]
  };

  constructor(private db: Firestore) {}

  ngOnInit() {
    const today = new Date();
    const lastWeek = new Date(today.setDate(today.getDate() - 1));
    this.startDate.setValue(lastWeek);
    this.loadChartData();
  }

  loadChartData() {
    const date = this.startDate.value || new Date();
    this.getPredictionsByDate(date).then(data => {
      this.series = this.processData(data);
    });
  }

  getPredictionsByDate(date: Date): Promise<any[]> {
    const promptsRef = collection(this.db, "relevancies");

    const dateStart = new Date(date);
    dateStart.setHours(0, 0, 0, 0);
    const dateEnd = new Date(date);
    dateEnd.setHours(23, 59, 59, 999);
    let constraints = [
      where('date', '>=', dateStart.toISOString().split(".")[0] + "Z"),
      where('date', '<=', dateEnd.toISOString().split(".")[0] + "Z"),
      orderBy('date', 'desc'),
      limit(10000)
    ];

    const q = query(promptsRef, ...constraints);
    return getDocs(q).then((snapshot) => {
      return snapshot.docs.map(doc => doc.data());
    });
  }

  processData(data: any[]): ApexAxisChartSeries {
    let points: { [key: string]: ChartDataPoint[] } = { long: [], cash: [], short: [] };
    data.forEach(item => {
      const point = {
        x: 100 * item.annualized_return,
        y: 100 * item.annualized_excess_return,
        fillColor: item.position === 'long' ? '#00FF00' : (item.position === 'cash' ? '#808080' : '#FF0000'),
        title: item.title,
        prompt_name: item.prompt_name,
        sector: item.sector,
        ticker: item.ticker,
        prompt_id: item.prompt_id
      };
      points[item.position].push(point);
    });

    return Object.keys(points).map(position => ({
      name: position.charAt(0).toUpperCase() + position.slice(1),
      data: points[position]
    }));
  }

  onDateChange(): void {
    if (this.startDate.value) {
      this.loadChartData();
    }
  }
}
