
export function getPositionSentiment(position: string): string {
  position = position?.toLowerCase();

  if (position === 'long') {
    return 'Positive';
  } else if (position === 'short') {
    return 'Negative';
  } else if (position === 'cash') {
    return 'Neutral';
  }

  return 'Unknown';
};
