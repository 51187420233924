<h2 mat-dialog-title>Terms and Conditions</h2>
<mat-dialog-content>
  <p>
    Before using the GPT Financial Analyst, please read and confirm your understanding of the following:
  </p>
  <p>
    1. General Information Only: The GPT Financial Analyst provides general financial insights and does not constitute
    personalized financial or investment advice.
  </p>
  <p>
    2. Risks Involved: Engaging with financial markets carries significant risks, including the potential loss of all
    invested funds. Leveraged products such as futures, options, and FX involve even higher levels of risk. Please refer
    to our full Risk Warning for a complete overview.
  </p>
  <p>
    3. No Guarantee of Accuracy: The information presented may not reflect real-time market data. Values are intended
    for informational purposes only and may not be actionable for trading decisions.
  </p>
  <p>
    4. Independent Financial Advice: Users should perform their own due diligence and consult a licensed financial
    advisor before making any financial decisions based on the information provided by this service.
  </p>
  <p>
    5. Intellectual Property: Unauthorized use, reproduction, or distribution of data or analysis from this tool
    without permission is prohibited.
  </p>
  <p>Please ensure you read, understand and agree to the Terms and Conditions, Privacy Policy, and Risk Warning before
    proceeding.
  </p>

  <a href="/terms-and-conditions" target="_blank">View full Terms and Conditions</a><br>
  <a href="/privacy-policy" target="_blank">View Privacy Policy</a><br>
  <a href="/risk-warning" target="_blank">View Risk Warnings</a>

</mat-dialog-content>
<mat-dialog-actions>

  <mat-checkbox [(ngModel)]="consent.agreed">
    I have read and agree to the Terms and Conditions, Privacy Policy, and Risk Warning.
  </mat-checkbox>

  <mat-checkbox [(ngModel)]="consent.ageVerified">
    I confirm that I am over 18 years old.
  </mat-checkbox>

  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button [disabled]="!canSubmit()" (click)="onSubmit()">Agree</button>
</mat-dialog-actions>
