@if(isAuthenticated$ | async) {
<span class="display-name" matTooltip="Username">
  {{ (isAnonymous$ |async) ? "Anonymous" : (displayName$ |async) }}
</span>
<mat-icon (click)="logout()" class="logout" matTooltip="Logout">
  logout
</mat-icon>
} @else {
<a routerLink="/login" class="login">Login</a>
}
