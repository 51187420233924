import { createSelector } from '@ngxs/store';
import { PredictionState, PredictionStateModel } from './prediction.state';
import { Prediction } from './prediction.model';

export class PredictionSelectors {
  static allForGenerator(id: string): (state: PredictionStateModel) => Prediction[] {
    return createSelector([PredictionState], (state: PredictionStateModel) => {
      return state.predictionsByGenerator[id];
    });
  }

  static selectedForGenerator(id: string): (state: PredictionStateModel) => Prediction {
    return createSelector([PredictionState], (state: PredictionStateModel): Prediction => {
      const selectedIndex = state.selectedIndex[id];
      const predictions = state.predictionsByGenerator[id];
      return predictions?.[selectedIndex];
    });
  }

  static isFirstPrediction(id: string): (state: PredictionStateModel) => boolean {
    return createSelector([PredictionState], (state: PredictionStateModel): boolean => {
      const selectedIndex = state.selectedIndex[id];
      return selectedIndex === 0;
    });
  }

  static isLastPrediction(id: string): (state: PredictionStateModel) => boolean {
    return createSelector([PredictionState], (state: PredictionStateModel): boolean => {
      const n = state.predictionsByGenerator[id]?.length || 0;
      const selectedIndex = state.selectedIndex[id];
      return selectedIndex >= n - 1;
    });
  }

  static isLoading(id: string): (state: PredictionStateModel) => boolean {
    return createSelector([PredictionState], (state: PredictionStateModel): boolean => {
      return state.predictionsByGenerator[id] === undefined;
    });
  }
}
