import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe, PercentPipe } from '@angular/common';

@Pipe({
  name: 'safeNumber'
})
export class SafeNumberPipe implements PipeTransform {

  constructor(private numberPipe: DecimalPipe) {}

  transform(value: any, fallback: string): any {
    try {
      return this.numberPipe.transform(value);
    } catch (e) {
      return fallback;
    }
  }

}

@Pipe({
  name: 'safePercent'
})
export class SafePercentPipe implements PipeTransform {

  constructor(private percentPipe: PercentPipe) {}

  transform(value: any, fallback: string): any {
    try {
      return this.percentPipe.transform(value);
    } catch (e) {
      return fallback;
    }
  }

}
