import { Prompt } from "src/app/model/prompt.model";

export enum NewsSummaryStatus {
  unavailable = 'unavailable',
  queued = 'queued',
  running = 'running',
  interrupted = 'interrupted',
  ready = 'ready',
  error = 'error'
}

export interface DataGenerator {
  news_summariser_id: string;
  name: string;
  description: string;
  ownerId: string;
  prompt: Prompt;
  summary: string;
  type: string;
  isOwner: boolean;

  run_freq: string,
  started_at: Date,
  timezone: string | undefined,
  symbol: string,
  tags?: string[],
  status?: NewsSummaryStatus,

  inputTokens?: number,
  outputTokens?: number,
  cost?: number
}
