import { createSelector, Selector } from '@ngxs/store';
import { PromptsState, PromptsStateModel } from './prompt.state';
import { Prompt } from '../../model/prompt.model';
import { UserState } from '../user/user.state';

export class PromptSelector {
  @Selector([PromptsState])
  static all(state: PromptsStateModel) {
    return state.prompts;
  }

  @Selector([PromptsState, UserState.getUserId])
  static allForUser(state: PromptsStateModel, userId: string) {
    return state.prompts.filter((p: Prompt) => p.owner_id === userId);
  }

  static allBySymbolAndType(symbol: string, type: string) {
    return createSelector([PromptsState], (state: PromptsStateModel) => {
      return state.prompts.filter((p: Prompt) => p.target_ticker === symbol && p.type === type);
    });
  }

  static allByUserId(userId: string) {
    return createSelector([PromptsState], (state: PromptsStateModel) => {
      return state.prompts.filter((p: Prompt) => p.owner_id === userId);
    });
  }

  static allBySymbolTypeAndUser(symbol: string, type: string, userId: string) {
    return createSelector([PromptsState], (state: PromptsStateModel) => {
      return state.prompts
        .filter((p: Prompt) => p.target_ticker === symbol && p.type === type && p.owner_id === userId);
    });
  }
}
