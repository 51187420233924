<h2 mat-dialog-title>
  <input
    matInput
    placeholder="Name"
    [(ngModel)]="generator.name" />
</h2>

<!-- MOBILE & TABLET -->
<mat-dialog-content class="container mobile">
  <div class="full-width">
    <mat-form-field class="full-width">
      <mat-label>Description</mat-label>
      <input
        matInput
        placeholder="Description"
        [(ngModel)]="generator.description" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>Definition</mat-label>
      <textarea
        matInput
        placeholder="Definition"
        [(ngModel)]="generator.prompt.template_body"
        rows="5">
      </textarea>
    </mat-form-field>
    
  </div>
</mat-dialog-content>

<!-- DESKTOP -->
<mat-dialog-content class="container desktop">
  <section>
    <mat-form-field class="full-width">
      <mat-label>Description</mat-label>
      <input
        matInput
        placeholder="Description"
        [(ngModel)]="generator.description" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>Start writing your prompt</mat-label>
      <textarea
        matInput
        placeholder="Definition"
        [(ngModel)]="generator.prompt.template_body">
      </textarea>
    </mat-form-field>

  </section>

  <section style="position: relative">
    <mat-tab-group [(selectedIndex)]="selectedTabIndex">

      <mat-tab label="Frequency and Start Date">
        <!-- Update Frequency (Cron string) -->
        <cron-editor  [(ngModel)]="generator.run_freq" [options]="cronOptions"></cron-editor>

        <!-- Earliest Run Date -->
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Earliest Run Date</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="generator.started_at" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-hint>Select the earliest run date</mat-hint>
        </mat-form-field>
      
        <!-- Timezone -->
        <!-- <mat-form-field appearance="fill" class="full-width">
          <mat-label>Timezone</mat-label>
          <mat-select [(ngModel)]="generator.timezone">
            <mat-option *ngFor="let tz of timezones" [value]="tz">{{ tz }}</mat-option>
          </mat-select>
          <mat-hint>Select the timezone for the run</mat-hint>
        </mat-form-field> -->

      </mat-tab>

      <mat-tab label="Data Items">
        <app-prompt-inputs (onInsert)="insertInput($event)"></app-prompt-inputs>
      </mat-tab>

    </mat-tab-group>

  </section>
</mat-dialog-content>

<mat-dialog-actions align="end">

  <button
    mat-stroked-button
    (click)="handleCancel()"
    title="Cancel the prompt editing. All changes are lost">
    Cancel
  </button>

  <button
    color="primary"
    mat-raised-button
    cdkFocusInitial
    (click)="save()">
    Save
  </button>

</mat-dialog-actions>
