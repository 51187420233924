<h1>Privacy Policy</h1>

<p>Last Updated: 20/09/2024</p>

<h2>General</h2>
<p>
  This Privacy Policy applies to the GPT Analyst website ("the Website") and the GPT Analyst mobile application ("the
  App"), collectively referred to as "the Service." We are committed to protecting your privacy in accordance with the
  Swiss Federal Data Protection Act (FADP) and, where applicable, the EU General Data Protection Regulation (GDPR). This
  Privacy Policy outlines our practices regarding the collection, use, and disclosure of your personal data through the
  Service.
</p>

<h2>Consent to Use Information</h2>
<p>
  By accessing, using, or providing information to the Service, you agree to the terms of this Privacy Policy and
  consent to our collection, use, and disclosure of your personal data as described herein. We encourage you to review
  this Privacy Policy periodically. If you disagree with any part of this policy, please discontinue use of the Service
  immediately.
</p>
<p>
  For further information, please refer to our “Terms and Conditions” and Cookie Policy, which are incorporated by
  reference into this Privacy Policy.
</p>

<h2>Information We Collect</h2>
<h3>Non-Personal Information</h3>

<p>
  We collect Non-Personal Information automatically through your use of the Service. This includes data such as your:
</p>

<ul>
  <li>Geo-location,</li>
  <li>Web requests,</li>
  <li>Browser type,</li>
  <li>Referring/exit pages,</li>
  <li>Time and date of use, and</li>
  <li>Interaction with the Service.</li>
</ul>

<p>
  This information is used for security, performance monitoring, auditing, and managing affiliate calculations.
</p>

<p>
  <strong>Cookies:</strong> We use cookies to enhance your experience, including remembering user preferences. For more
  details, refer to our Cookie Policy.
</p>

<p>
  <strong>Web Beacons:</strong> We use web beacons to monitor site usage and visitor behavior, allowing us to optimize
  the performance and user experience of the Service.
</p>

<h3>Personal Information</h3>

<p>Personal Information may include:</p>

<ul>
  <li>Name,</li>
  <li>Gender,</li>
  <li>Phone number,</li>
  <li>Email address,</li>
  <li>Mailing address, and</li>
  <li>Payment information</li>
</ul>

<p>
  This data is collected when you create an account, interact with our Service, or make purchases. You may also choose
  to sign up using a third-party account, such as Facebook or Google, which will require your consent to access certain
  Personal Information stored in those accounts.
</p>

<p>
  We treat any linked Non-Personal and Personal Information collectively as Personal Information, processed under your
  consent, our contractual obligations, or our legitimate business interests.
</p>

<h3>Financial Data</h3>

<p>
  We collect and process payment and financial data, such as credit card or bank information, to fulfill your purchase
  requests and manage your account. This data is handled with strict security standards to ensure your protection.
</p>

<h3>Usage Data</h3>

<p>We collect Usage Data such as:</p>

<ul>
  <li>Browsing history,</li>
  <li>Device information,</li>
  <li>Click-stream data, and</li>
  <li>Other interactions with the Service.</li>
</ul>

<p>
  This data helps us improve the functionality and performance of the Service.
</p>

<h3>Use of Information</h3>

<p>We use the collected information to:</p>

<ul>
  <li>Provide and manage the services requested by you.</li>
  <li>Enhance and customize our services based on your interactions and preferences.</li>
  <li>Communicate with you, including providing updates, notifications, and marketing offers (subject to your
    preferences).</li>
  <li>Conduct internal research and analytics to improve our service offering and performance.</li>
</ul>

<h2>Legal Basis for Processing Personal Data</h2>

<p>
  Under Swiss law and applicable GDPR provisions (if relevant), we rely on the following legal bases for processing your
  personal data:
</p>

<ul>
  <li><b>Consent:</b> When you have provided your clear consent to process your personal data for specific purposes (e.g.,
    marketing).</li>
  <li><b>Contractual Necessity:</b> Processing is necessary for the performance of a contract to which you are a party, such as
    providing services or processing payments.</li>
  <li><b>Legal Obligation:</b> Where we are legally required to process your data (e.g., tax obligations).</li>
  <li><b>Legitimate Interests:</b> Where processing is necessary for our legitimate business interests, provided such interests
    are not overridden by your privacy rights.</li>
</ul>

<h2>Sharing of Information</h2>

<p>We may share your personal information in the following instances:</p>

<p>Service Providers and Partners: We share your data with trusted third-party service providers who assist in operating
  our services (e.g., Google Analytics, hosting providers, payment processors). These partners are obligated to protect
  your data in compliance with applicable privacy laws.</p>

<p>Legal Compliance: We may disclose your information to legal authorities or regulatory bodies when required to comply
  with applicable laws, regulations, or legal processes.</p>

<p>Business Transactions: In the event of a merger, acquisition, or sale of assets, your personal data may be
  transferred as part of the transaction. We will notify you of any such transfer and ensure your data remains protected
  under this Privacy Policy.</p>

<h2>International Data Transfers</h2>

<p>As a Swiss company, your data may be stored and processed within Switzerland or other jurisdictions. When
  transferring your personal data outside of Switzerland or the European Economic Area (EEA), we ensure an adequate
  level of protection in line with Swiss and EU regulations. This may involve using Standard Contractual Clauses (SCCs)
  or other legally recognized safeguards.</p>

<h2>Your Rights</h2>

<p>Under Swiss law and, where applicable, the GDPR, you have the following rights regarding your personal data:</p>

<ul>
  <li><b>Right to Access:</b> You may request a copy of the personal data we hold about you.</li>
  <li><b>Right to Rectification:</b> You can request corrections to any inaccurate or incomplete personal data.</li>
  <li><b>Right to Erasure:</b> You have the right to request that we delete your personal data, subject to legal retention
    requirements.</li>
  <li><b>Right to Data Portability:</b> You may request to receive your personal data in a structured, commonly used, and
    machine-readable format.</li>
  <li><b>Right to Restrict Processing:</b> You can request limitations on how we process your data under certain circumstances.
  </li>
  <li><b>Right to Withdraw Consent:</b> If we process your data based on your consent, you may withdraw your consent at any
    time.</li>
  <li><b>Right to Object:</b> You may object to the processing of your personal data where we rely on legitimate interests as
    the legal basis.</li>
</ul>

<h2>Data Security</h2>

<p>
  We implement appropriate technical and organizational security measures to safeguard your personal data from
  unauthorized access, alteration, and loss. These include encryption, firewalls, and secure access controls. However,
  no method of transmission over the Internet or electronic storage is entirely secure, and we cannot guarantee absolute
  security.
</p>

<p>
  In the event of a data breach, we will notify affected individuals and the relevant authorities in accordance with
  applicable Swiss law and GDPR (if applicable).
</p>

<h2>Third-Party Services</h2>

<p>Our Service may contain links to third-party websites or services that are not operated by us. We are not responsible
  for the privacy practices of such third-party sites or services. We recommend reviewing the privacy policies of those
  third parties before interacting with them.</p>

<h2>Changes to This Policy</h2>

<p>
  We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We
  will notify you of any significant changes by email or through the Service, and changes will become effective as of
  the date indicated in the notice.
</p>

<h2>Contact Us</h2>

<p>
  If you have any questions about this Privacy Policy, please contact us at:
</p>

<ul>
  <li>Email: info&#64;logicflow.ai</li>
  <li>Address: LogicFlow AG, Butzenstrasse 130, 8041 Zürich</li>
</ul>
