import { Injectable } from '@angular/core';
import { collection, getDocs, Firestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TriggerService {
  // Define the type for knownSchemas explicitly
  private knownSchemasSubject = new BehaviorSubject<Record<string, Record<string, any>>>({});
  knownSchemas$: Observable<Record<string, Record<string, any>>> = this.knownSchemasSubject.asObservable();

  // Observable that emits the keys of knownSchemas whenever it changes
  schemaKeys$: Observable<string[]> = this.knownSchemas$.pipe(
    map((schemas: Record<string, Record<string, any>>) => Object.keys(schemas))
  );

  constructor(private db: Firestore) {
    this.loadSchemas();
  }

  private async loadSchemas(): Promise<void> {
    const triggerRef = collection(this.db, 'triggers');
    const querySnapshot = await getDocs(triggerRef);
    const schemas: Record<string, Record<string, any>> = {};
    querySnapshot.forEach((doc) => {
      console.log("Schema: ", doc.data());
      schemas[doc.data()["properties"]["name"]["const"]] = doc.data();
    });
    this.knownSchemasSubject.next(schemas);
  }

  getSchemaByName(name: string): Record<string, any> | undefined {
    if (!this.knownSchemasSubject.value) {
      return undefined;
    } else {
      return this.knownSchemasSubject.value[name];
    }
  }
}
