import { Injectable, Optional } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { FirebaseHttpService } from './firebase-http.service';
import { DocumentSnapshot, doc, getDoc, onSnapshot, Unsubscribe, Firestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class SymbolService {
  private detailUnsubscribe: Unsubscribe | undefined;
  private performanceUnsubscribe: Unsubscribe | undefined;
  private marketInfoUnsubscribe: Unsubscribe | undefined;
  private isLoading: any = {};

  symbolSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  symbol$: Observable<string> = this.symbolSubject.asObservable();

  symbolDetailsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  symbolDetails$: Observable<any> = this.symbolDetailsSubject.asObservable();

  symbolPerformanceSubject: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  symbolPerformance$: Observable<any> = this.symbolPerformanceSubject.asObservable();

  symbolMarketInfoSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  symbolMarketInfo$: Observable<any> = this.symbolMarketInfoSubject.asObservable();

  lastPriceDetails$: Observable<any> = this.symbolPerformance$.pipe(
    map((data: any) => {
      const entries = Object.entries(data);
      const lastEntry: any = entries.pop();
      if (!lastEntry) return;

      const secondLastEntry: any = entries.pop();
      if (!secondLastEntry) return;

      const lastPrice = lastEntry[1]['5. adjusted close'];
      const secondLastPrice = secondLastEntry[1]['5. adjusted close'];
      return {
        lastPrice: lastPrice,
        priceChange: lastPrice - secondLastPrice,
        priceChangePercent: ((lastPrice - secondLastPrice) / secondLastPrice) * 100,
      };
    })
  );

  constructor(
    private fbHttps: FirebaseHttpService,
    private db: Firestore,
    @Optional() private toastr?: ToastrService)
  { }

  select(symbol: string | undefined) {
    if (this.symbolSubject.value === symbol) return;

    this.symbolSubject.next(symbol);
    if (!symbol) {
      this.symbolDetailsSubject.next(undefined);
      this.symbolPerformanceSubject.next([]);
      return;
    }

    this.loadDetails(symbol);
    this.loadPerformance(symbol);
    this.loadMarketInfo(symbol);
  }

  loadDetails(symbol: any) {
    const overview = doc(this.db, 'data', symbol, 'OVERVIEW', 'd41d8cd98f00b204e9800998ecf8427e');
    let reload: boolean = false;
    this.detailUnsubscribe?.();
    this.detailUnsubscribe = onSnapshot(overview, (odoc) => {
      const now = new Date().getTime() / 1000;
      const exists = odoc.exists();
      reload = exists ? odoc.data()['stale_at']['seconds'] < now : true;

      if (exists) {
        const doc_data = odoc.data()['data'];
        this.setDetails(doc_data);
      }

      const key = 'get_ticker_overview' + symbol;
      if (reload && !this.isLoading[key]) {
        console.log("Data doesn't exist or is out of date, reloading it.");
        this.isLoading[key] = true;
        this.fbHttps
          .call('api', { method: 'get_ticker_overview', symbol: symbol })
          .catch((error) => {
            this.toastr?.error(error, 'Error loading details');
          })
          .finally(() => {
            this.isLoading[key] = false;
          });
      }
    });
  }

  setDetails(data: any) {
    if (data.Symbol !== this.symbolSubject.value) return;

    this.symbolDetailsSubject.next({
      Name: 'Not Found',
      Symbol: '???',
      Description: 'Missing',
      Currency: '???',
      ...data,
    });
  }

  loadPerformance(symbol: string) {
    // This works because the hash is the same for all symbols.
    // Should the hashing algorithm change, this needs to be updated.
    const adjusted = doc(this.db, 'data', symbol, 'TIME_SERIES_DAILY_ADJUSTED', '44b7f13ccc0be54a57a35424327db12c');
    let reload: boolean = false;

    this.performanceUnsubscribe?.();
    this.performanceUnsubscribe = onSnapshot(adjusted, (pdoc) => {
      const now = new Date().getTime() / 1000;
      const exists = pdoc.exists();
      reload = exists ? pdoc.data()['stale_at']['seconds'] < now : true;

      if (exists) {
        getDoc(doc(this.db, pdoc.ref.path, 'Time+Series+%28Daily%29', '5.+adjusted+close'))
          .then((sdoc) => {
            if (sdoc.exists()) {
              const data: any = sdoc.data()['data'];
              const sortedData: any = {};
              const sortedKeys = Object.keys(data).sort();
              sortedKeys.forEach((x) => {
                sortedData[x] = data[x];
              });
              this.setPerformance(symbol, sortedData);
            } else {
              reload = true;
            }
          })
          .catch((error) => {
            this.toastr?.error(error, 'Error loading series');
          });
      }

      const key = 'get_ticker_daily_series' + symbol;
      if (reload && !this.isLoading[key]) {
        console.log("Data doesn't exist or is out of date, reloading it.");
        this.isLoading[key] = true;
        this.fbHttps
          .call('api', {
            method: 'get_ticker_daily_series',
            symbol: symbol,
            series: ['5. adjusted close'],
          })
          .catch((error) => {
            this.toastr?.error(error, 'Error loading series');
          })
          .finally(() => {
            this.isLoading[key] = false;
          });
      }
    });
  }

  setPerformance(symbol: string, data: any) {
    if (symbol !== this.symbolSubject.value) return;
    this.symbolPerformanceSubject.next(data);
  }

  loadMarketInfo(symbol: string) {
    const market = doc(this.db, 'data', symbol, '_info', 'times');
    this.marketInfoUnsubscribe?.();
    this.marketInfoUnsubscribe = onSnapshot(market, (odoc) => {
      const exists = odoc.exists();
      if (exists) {
        this.symbolMarketInfoSubject.next(odoc.data());
      } else {
        this.symbolMarketInfoSubject.next({});
      }
    });
  }

  async loadOverview(): Promise<DocumentSnapshot> {
    return getDoc(doc(this.db, 'data', 'market'));
  }
}
