<div class="title-container" (click)="toggle()">
  <mat-icon>{{ sectionOpened ? 'expand_less' : 'expand_more' }}</mat-icon>
  <h2>News Summarizers</h2>
</div>

<div class="news-carousel" *ngIf="sectionOpened">
  <div class="news-container">
    <div *ngIf="(generators | async)?.length === 0">
      No items found
    </div>

    <div *ngFor="let g of generators | async" class="news-tile">
      <app-data-generator-detail [generator]="g" (clone)="clonePrompt($event)"></app-data-generator-detail>
    </div>

  </div>
</div>

<div class="new-wrapper" *ngIf="sectionOpened">
  @if(isAuthenticated$ | async) {
    <mat-spinner *ngIf="isCreating$ | async" diameter="20" class="prompt-creating"></mat-spinner>
    <button mat-raised-button color="primary"
            *ngIf="!(isCreating$ | async)"
            (click)="newPrompt()"
            class="add-prompt"
            title="Create new summarizer">
      New
    </button>
  } @else {
    <div class="login">
      <p>Login to create and edit news summarizers</p>
      <button mat-raised-button [routerLink]="['/login']">Login</button>
    </div>
  }
</div>
