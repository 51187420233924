import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject, takeUntil } from 'rxjs';
import { Prompt } from '../../model/prompt.model';
import { ToggleActiveStatus, ToggleTradedStatus, DeletePrompt, LoadUserPrompts } from '../../store/prompt/prompt.state';
import { PromptSelector } from '../../store/prompt/prompt.selector';

@Component({
  selector: 'app-user-prompts',
  templateUrl: './user-prompts.component.html',
  styleUrls: ['./user-prompts.component.scss']
})
export class UserPromptsComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['name', 'symbol', 'active', 'actions'];
  dataSource = new MatTableDataSource<Prompt>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private router: Router)
  {}

  ngOnInit(): void {
    this.loadUserPrompts();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadUserPrompts(): void {
    this.store
      .select(PromptSelector.allForUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((prompts: Prompt[]) => {
        this.dataSource.data = prompts;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  toggleActiveStatus(prompt: Prompt): void {
    if (!prompt.promptId) return;
    this.store.dispatch(new ToggleActiveStatus(prompt.promptId));
  }

  toggleTradedStatus(prompt: Prompt): void {
    if (!prompt.promptId) return;
    this.store.dispatch(new ToggleTradedStatus(prompt.promptId));
  }

  deletePrompt(prompt: Prompt): void {
    if (!prompt.promptId) return;
    this.store.dispatch(new DeletePrompt(prompt.promptId));
  }

  viewPrompt(prompt: Prompt): void {
    this.router.navigate(['ticker', prompt.target_ticker, prompt.promptId]);
  }
}
