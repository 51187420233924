<ng-container *ngIf="prompt$ | async; then withPrompt; else: noPrompt">
  <ng-container *ngIf="prediction$ | async; then withPrediction; else: noPrediction">
  </ng-container>
</ng-container>

<ng-template #withPrediction>
  <div class="carousel-container" *ngIf="prediction$ | async; let prediction">
    <div class="navigation">
      <div class="description">
        <div class="prompt-name" *ngIf="prompt$ | async; let prompt">
          {{ prompt.name }}:
        </div>
        <div class="current position" [class]="decision">
          {{ this.transformDate(carouselItems[currentIndex]?.prediction_date) }}
        </div>
      </div>

      <div class="arrows">
        <div>
          <button
            mat-icon-button
            (click)="navigate(true)"
            *ngIf="!isLast"
            class="chevron-right"
          >
            <mat-icon>chevron_right</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="navigate(false)"
            *ngIf="!isFirst"
            class="chevron-left"
          >
            <mat-icon>chevron_left</mat-icon>
          </button>
        </div>

        <button
          mat-stroked-button
          color="primary"
          class="view-prompt"
          (click)="togglePrompt()"
        >
          {{ !showPrompt ? "View Prompt" : "Hide Prompt" }}
        </button>
      </div>
      </div>
    <div class="prediction">
      <span *ngIf="!showPrompt">{{ prediction.output.reasoning }}</span>
      <span *ngIf="showPrompt">{{ prediction.input }}</span>
    </div>
  </div>
</ng-template>

<ng-template #noPrompt>
  <div class="carousel-container">
    <div class="no-prediction">Please select a prompt to see predictions.</div>
  </div>
</ng-template>

<ng-template #withPrompt>
  <ng-container *ngIf="prediction$ | async; then withPrediction; else: noPrediction">
  </ng-container>
</ng-template>

<ng-template #noPrediction>
  <div class="carousel-container no-prediction">
    <div class="prompt-name" *ngIf="prompt$ | async; let prompt">
      {{ prompt.name }}:
    </div>
    <div class="no-prediction-text">No predictions available.</div>
  </div>
</ng-template>
