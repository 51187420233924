<mat-spinner *ngIf="!overview"></mat-spinner>
<div class="header" *ngIf="overview">
  <div class="panel left">
    <h1>{{overview?.Name}} ({{ overview?.Symbol }})</h1>
    <app-prompt-viewing [selectedDate]="selectedDate"></app-prompt-viewing>
  </div>
  <div class="panel right">
    <div class="chart-title" [class.positive]="positive$ | async" [class.negative]="!(positive$ | async)">
      <span class="currency">{{ overview?.Currency }}</span>
      <span class="price">{{ price$ | async | number:'1.2-2' }}</span>
      <span class="change">
        <span class="plus">+</span>
        {{ priceChange$ | async | number:'1.2-2' }}
      </span>
      <span class="change percent">
        (<span class="plus">+</span> {{ priceChangePercent$ | async | number:'1.2-2' }}%)
      </span>
    </div>
    <app-interactive-chart (dateSelected)="onDateSelected($event)"></app-interactive-chart>
  </div>
</div>

<app-prompt-items></app-prompt-items>
<app-metrics-overview></app-metrics-overview>
<app-prompt-list></app-prompt-list>

<a routerLink="/feedback" class="feedback-button">
  <button mat-stroked-button color="primary" title="Provide feedback or request features">
    Request Feature & Feedback
  </button>
</a>
