import { Component, Inject, OnInit, Optional, untracked } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { angularMaterialRenderers } from '@jsonforms/angular-material';
import { Generate, ValidationMode, createAjv } from "@jsonforms/core"
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';

import { PromptService } from 'src/app/services/prompt.service';
import { TriggerService } from 'src/app/services/trigger.service';
import { UserState } from 'src/app/store/user/user.state';


@Component({
  selector: 'app-backtest-dialog',
  templateUrl: './backtest-dialog.component.html',
  styleUrls: ['./backtest-dialog.component.scss']
})
export class BacktestDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<BacktestDialogComponent>,
    private fb: FormBuilder,
    private store: Store,
    private promptService: PromptService,
    private triggerService: TriggerService,
    @Inject(MAT_DIALOG_DATA) public data: { prompt: any, promptId: string },
    @Optional() private toastr?: ToastrService)
  {}

  time: string = '';
  startDate: Date | null = null;
  endDate: Date | null = null;
  triggers: any[] = [];  // List of added components

  // Display settings
  hasOperation = false;
  showOptions: boolean = false;

  // Trigger dropdown data
  selectedSchema: string | undefined = undefined;
  schemas: string[] = [];

  // Fields for the JSON Schema form for triggers
  ajv = createAjv()
  renderers = angularMaterialRenderers;
  schema: any = undefined;
  uischema: any = undefined;
  triggerConfig: any = {};
  validationMode: ValidationMode = "ValidateAndHide";

  // Overall run configuration
  runConfigForm: FormGroup = this.fb.group({
    runTime: ['09:30', Validators.required],
    backtestConfig: this.fb.group({
      startDate: [null, Validators.required],
      endDate: [],
      triggers: [[], [Validators.required, Validators.minLength(1)]],
    }),
    llmConfig: this.fb.group({
      temperature: new FormControl({
        value: 0.0,
        disabled: true
      }, [Validators.required, Validators.min(0.0), Validators.max(1.0)]),
      model: new FormControl({
        value: 'gpt-4o-mini',
        disabled: true
      }, [Validators.required]),
    })
  });
  triggerTrades: Date[] = [];

  ngOnInit(): void {
    this.triggerService.schemaKeys$.subscribe((keys) => {
      this.schemas = keys;
      this.setTrigger('Weekly');
    });
  }

  setTrigger(name: string): void {
    this.schema = this.triggerService.getSchemaByName(name);
    if (!this.schema) return;

    this.onSchemaChange();
    this.triggerConfig = {
      name: name,
      weekday: 1
    };
    this.submitTrigger();
  }

  // Open dialog to add a new component
  addComponent(): void {
    this.showOptions = true;
  }

  // Remove a component from the list
  removeTrigger(index: number): void {
    this.triggers.splice(index, 1);
    this.promptService.backtestDates(
      this.data.prompt.target_ticker, this.runConfigForm.value
    )?.then((dates: Date[]) => {
      this.triggerTrades = dates;
    })
  }

  onSchemaChange(): void {
    if (!this.selectedSchema) {
      return;
    }

    this.schema = this.triggerService.getSchemaByName(this.selectedSchema);
    this.uischema = Generate.uiSchema(this.schema);
    this.uischema["elements"] = this.uischema["elements"].filter(
      (s: any) => s["scope"] !== "#/properties/name"
    );
    this.triggerConfig = {"name": this.schema["properties"]["name"]["const"]};
    // console.log("Schema: ", this.schema);
    // console.log("UISchema: ", this.uischema);
  }

  onDataChange(event: any): void {
    this.triggerConfig = event;
  }

  submitTrigger(): void {
    if (!this.ajv.validate(this.schema, this.triggerConfig)) {
      this.validationMode = "ValidateAndShow"
      return
    }

    this.triggers.push(this.triggerConfig);
    // console.log("Added component: ", this.triggerConfig);
    this.runConfigForm.get("backtestConfig")?.get("triggers")?.setValue(this.triggers);
    this.triggerConfig = {};
    this.resetForm();
    this.promptService.backtestDates(
      this.data.prompt.target_ticker, this.runConfigForm.value
    )?.then((dates: Date[]) => {
      this.triggerTrades = dates;
    })
  }

  closeTrigger(): void {
    this.resetForm();
  }

  private resetForm(): void {
    this.triggerConfig = {};
    this.schema = undefined;
    this.uischema = undefined;
    this.showOptions = false;
    this.validationMode = "ValidateAndHide"
    this.selectedSchema = undefined;
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  run(): void {
    if (this.runConfigForm.invalid) {
      this.toastr?.error(
        'Please fill in all required fields. At least one trigger is required.',
        'Error triggering backtest'
      );
      return;
    }

    const isMaxBacktestReached = this.store.selectSnapshot(UserState.isMaxBacktestsReached);
    if (isMaxBacktestReached) {
      this.toastr?.error(
        'You have reached your maximum of backtests per day. Please wait or \
        upgrade to increase capacity.',
        'Error triggering backtest'
      );
      return;
    }

    this.hasOperation = true;
    this.promptService.backtest(
      this.data.promptId,
      this.runConfigForm.value
    )?.then(
      (_) => {this.dialogRef.close(false);}
    ).catch(
      (_) => {
        this.toastr?.error(
          'Back-test configuration is invalid.',
          'Error triggering backtest.'
        );
      }
    ).finally(() => {
      this.hasOperation = false;
    });
  }

  tradeCount(): number {
    return this.triggerTrades.length;
  }
}
