import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { Logout, UserState } from '../../store/user/user.state';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  isAuthenticated$ = this.store.select(UserState.isAuthenticated);
  isAnonymous$ = this.store.select(UserState.isAnonymous);
  displayName$ = this.store.select(UserState.displayName);

  constructor(private store: Store) {}

  logout() {
    this.store.dispatch(new Logout());
  }
}
