import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DetailsComponent } from './details/details.component';
import { LoginPageComponent } from './components/login/login-page/login-page.component';
import { TermsAndConditionsComponent } from './legal/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './legal/privacy-policy/privacy-policy.component';
import { RiskWarningComponent } from './legal/risk-warning/risk-warning.component';
import { ProductPageComponent as ProductPageComponent } from './home/product/product.component';
import { ConsultingPageComponent as ConsulingPageComponent } from './home/consulting/consulting.component';
import { GaHelpComponent } from './home/ga-help/ga-help.component';
import { FeedbackCannyComponent } from './components/feedback-canny.component';
import { GaHomeComponent } from './home/ga-home.component';
import { ProfileComponent } from './profile/profile.component';
import { NotAuthorizedComponent } from './legal/not-authorized/not-authorized.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { UserPromptsComponent } from './profile/user-prompts/user-prompts.component';
import { GaAutocompleteComponent } from './components/ga-autocomplete/ga-autocomplete.component';
import { LandingComponent } from './home/landing/landing.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminGuard } from './guards/admin.guard';

const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'about', component: LandingComponent },
  { path: 'news', component: GaHomeComponent },
  { path: 'search', component: GaHomeComponent },
  { path: 'ticker/:symbol', component: DetailsComponent },
  { path: 'ticker/:symbol/:promptId', component: DetailsComponent },
  { path: 'login', component: LoginPageComponent },
  { path: 'help', component: GaHelpComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'profile/prompts', component: UserPromptsComponent },

  // Admin
  {
    path: 'admin',
    component: AdminDashboardComponent,
    canActivate: [AdminGuard]
  },
  {
    path: 'statistics',
    component: StatisticsComponent,
    canActivate: [AdminGuard]
  },

  // Legal
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'risk-warning', component: RiskWarningComponent },
  { path: 'not-authorized', component: NotAuthorizedComponent },

  // Company
  { path: 'consulting', component: ConsulingPageComponent },
  { path: 'product', component: ProductPageComponent },
  {
    path: 'feedback',
    component: FeedbackCannyComponent,
    children: [{
      path: '*',
      component: FeedbackCannyComponent
    }]
  },

  // WIP
  { path: 'autocomplete', component: GaAutocompleteComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
