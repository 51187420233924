import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { FirebaseHttpService } from '../../services/firebase-http.service';
import { DataItem } from './data-item.model';

export interface DataItemStateModel {
  items: { [symbol: string]: DataItem[] };
}

export class LoadDataItems {
  static readonly type = '[DataItem] Load';
  constructor(public symbol: string) {}
}

@State<DataItemStateModel>({
  name: 'dataItemState',
  defaults: {
    items: {}
  }
})
@Injectable()
export class DataItemState {
  constructor(private httpClient: FirebaseHttpService) {}

  static getDataItemsBySymbol(symbol: string) {
    return createSelector([DataItemState], (state: DataItemStateModel) => {
      return state.items[symbol] || [];
    });
  }

  static getDataItemsBySymbolAndClass(symbol: string, className: string) {
    return createSelector([DataItemState], (state: DataItemStateModel) => {
      const items = state.items[symbol] || [];
      return items.filter(item => item._class === className);
    });
  }

  @Action(LoadDataItems)
  async loadDataItems(ctx: StateContext<DataItemStateModel>, action: LoadDataItems) {
    const state = ctx.getState();
    const symbol = action.symbol;

    if (state.items[symbol]) {
      // Symbol already loaded, no need to fetch again
      return;
    }

    // Load data using the callable cloud function
    const result = await this.httpClient.call('api', { method: 'get_fields', symbol }) as any;
    const dataItems: DataItem[] = result.data.map((i: any) => {
      return { ...i, description: this.patternToDescription(i.pattern) } as DataItem;
    });

    ctx.patchState({
      items: {
        ...state.items,
        [symbol]: dataItems
      }
    });
  }

  patternToDescription(pattern: string): string {
    if (pattern.includes('<_date_from>')) {
      return '|Date from|Date to|Symbol<br>Example last seven days: -7|-0|AAPL';
    } else if (pattern.includes('<symbol>')) {
      return '|Symbol<br>Example: |AAPL';
    } else {
      return '';
    }
  }
}
