<div class="left">

  <h1>Your expert for LLMs in Finance to</h1>

  <div class="item-wrapper">
    <h3>Integrate</h3>
    <p>
      Your data sources with LLMs
    </p>
  </div>

  <div class="item-wrapper">
    <h3>Engineer</h3>
    <p>
      Prompts that work
    </p>
  </div>

  <div class="item-wrapper">
    <h3>Fine-Tune</h3>
    <p>
      LLMs to your needs and goals
    </p>
  </div>

  <div class="item-wrapper">
    <h3>Explore</h3>
    <p>
      How LLMs can boost your productivity
    </p>
  </div>

</div>

<div class="right">
  <img src="/assets/collaborate.png" class="info-graphic">
</div>

<div class="about-us-container">

  <p class="contact-us">
    Contact us: <a href="mailto:contact@logicflow.ai">contact&#64;logicflow.ai</a>
  </p>

  <section class="team">
    <h2>Meet the Team</h2>
    <div class="team-member">
      <img src="assets/lucas.jpeg" alt="Dr. Lucas Fievet">
      <h3>Dr. Lucas Fievet</h3>
      <p>
        CEO - Over 10 years of experiences in delivering successful enterprise software and AI projects.
      </p>
    </div>
    <div class="team-member">
      <img src="assets/andreas.jpeg" alt="Dr. Andreas Thomik">
      <h3>Dr. Andreas Thomik</h3>
      <p>
        CTO - Over 10 years of experience in technology and AI development. Former BCG consultant and successful
        founder.
      </p>
    </div>
    <div class="team-member">
      <img src="assets/cyril.png" alt="Dr. Cyril Misev">
      <h3>Dr. Cyril Misev</h3>
      <p>Tech Lead - Expert in AI systems and software architecture.</p>
    </div>
    <div class="team-member">
      <img src="assets/vladimir.jpeg" alt="Vladimir Adamov">
      <h3>Vlad Avanov</h3>
      <p>Senior Front End Lead - Skilled in creating intuitive user interfaces.</p>
    </div>
    <div class="team-member">
      <img src="assets/faruk.jpeg" alt="Faruk Ohran">
      <h3>Faruk Ohran</h3>
      <p>Front End Developer - Specializes in responsive and adaptive front-end design.</p>
    </div>
    <div class="team-member">
      <img src="assets/nadine.jpeg" alt="Nadine How">
      <h3>Nadine How</h3>
      <p>Executive Assistant - All back-office operations.</p>
    </div>

    <p class="contact-us">
      Contact us: <a href="mailto:contact@logicflow.ai">contact&#64;logicflow.ai</a>
    </p>
  </section>

  <div class="trusted">
    <div class="trusted-header">
      Trusted By
    </div>
    <div class="trusted-item">
      <img src="/assets/zurich.png" height="48px">
    </div>
    <div class="trusted-item">
      <img src="/assets/raiffeisen.svg" height="32px">
    </div>
    <div class="trusted-item">
      <img src="/assets/tiko.svg" height="42px">
    </div>
  </div>
</div>