import { createSelector, Selector } from '@ngxs/store';
import { DataGeneratorState, DataGeneratorStateModel } from './data-generator.state';
import { DataGenerator } from './data-generator.model';

export class DataGeneratorSelectors {
  @Selector([DataGeneratorState])
  static selectAll(state: DataGeneratorStateModel) {
    return state.dataGenerators;
  }

  static selectBySymbol(symbol: string) {
    return createSelector([DataGeneratorState], (state: DataGeneratorStateModel) => {
      return state.dataGenerators.filter((g: DataGenerator) => g.tags?.includes(`symbol:${symbol}`) ?? false);
    });
  }

  static selectById(id: string) {
    return createSelector([DataGeneratorState], (state: DataGeneratorStateModel) => {
      return state.dataGenerators.find((g: DataGenerator) => g.news_summariser_id === id);
    });
  }

  @Selector([DataGeneratorState])
  static isCreating(state: DataGeneratorStateModel) {
    return state.isCreating;
  }
}
