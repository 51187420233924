import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { PredictionRelevancyService } from '../../services/prediction-relevancy.service';
import { NewsNavigationService } from './news-navigation.service';
import { getPositionSentiment } from '../../utils';

@Component({
  selector: 'app-news-overview',
  templateUrl: './news-overview.component.html',
  styleUrl: './news-overview.component.scss'
})
export class NewsOverviewComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  // These news will be loaded from Firestore
  maxLength = 850;
  newsList: any[] = [];
  industries = ['Finance', 'Technology', 'Healthcare'];
  isFirst = true;
  isLast = false;

  selectedIndustry!: string;
  searchQuery!: string;

  get loading$(): Observable<boolean> {
    return this.predictionRelevancyService.loading.asObservable();
  }

  get currentNews(): any[] {
    return this.newsNavigationService.currentNews;
  }

  set currentNews(value: any[]) {
    this.newsNavigationService.currentNews = value;
  }

  get newsIndex(): number {
    return this.newsNavigationService.newsIndex;
  }

  set newsIndex(value: number) {
    this.newsNavigationService.newsIndex = value;
  }

  get itemsPerPage(): number {
    return this.newsNavigationService.itemsPerPage;
  }

  set itemsPerPage(value: number) {
    this.newsNavigationService.itemsPerPage = value;
  }

  get searchSectionOpened(): boolean {
    return this.newsNavigationService.searchSectionOpened;
  }

  set searchSectionOpened(value: boolean) {
    this.newsNavigationService.searchSectionOpened = value;
  }

  get startDate(): Date | undefined {
    return this.newsNavigationService.startDate;
  }

  set startDate(value: Date) {
    this.newsNavigationService.startDate = value;
  }

  get endDate(): Date | undefined {
    return this.newsNavigationService.endDate;
  }

  set endDate(value: Date) {
    this.newsNavigationService.endDate = value;
  }

  get performance(): string {
    return this.newsNavigationService.performance;
  }

  set performance(value: string) {
    this.newsNavigationService.performance = value;
  }

  get positionChange(): boolean {
    return this.newsNavigationService.positionChange;
  }

  set positionChange(value: boolean) {
    this.newsNavigationService.positionChange = value;
  }

  constructor(
    private predictionRelevancyService: PredictionRelevancyService,
    private newsNavigationService: NewsNavigationService)
  { }

  ngOnInit(): void {
    this.setMaxLength();

    this.predictionRelevancyService.relevancies$
      .pipe(takeUntil(this.destroy$))
      .subscribe((relevancies: any[]) => {
        this.newsList = relevancies.map((relevancy: any) => {
          return {
            ...relevancy,
            sentiment: getPositionSentiment(relevancy.position),
            position: this.capitalizeFirstLetter(relevancy.position),
            content: relevancy.output.reasoning.trim(),
            date: new Date(relevancy.date)
          };
        });

        this.renderNews();
      });

    this.predictionRelevancyService.lastDate.asObservable().subscribe((date: Date) => {
      date.setDate(date.getDate() - 1);
      date.setMinutes(date.getMinutes() - 15);
      this.startDate = date;
    });

    // News only needs to be initialized when the list is empty
    if (this.newsList.length === 0) {
      this.loadMore();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onFilterChange() {
    this.load(true);
  }

  onPositionChange() {
    this.load(true);
  }

  capitalizeFirstLetter(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  load(reset: boolean = false) {
    const filter: any = {
      startDate: this.startDate?.toISOString().split(".")[0] + "Z",
      endDate: this.endDate?.toISOString().split(".")[0] + "Z",
      performance: this.performance,
      positionChange: this.positionChange,
      method: 'Levenshtein'
    };
    this.predictionRelevancyService.load(filter, reset);

    if (reset) {
      this.reset();
    }
  }

  reset() {
    this.newsList = [];
    this.currentNews = [];
    this.newsIndex = 0;
    this.checkNavigation();
  }

  loadMore() {
    if (this.newsIndex + this.itemsPerPage >= this.newsList.length) {
      this.load();
      return;
    }

    this.itemsPerPage += 3;
    if (this.newsList.length <= this.itemsPerPage) {
      this.loadMore();
    } else {
      this.renderNews();
    }
  }

  renderNews() {
    const start = Math.max(this.newsIndex, 0);
    const end = start + this.itemsPerPage;
    this.currentNews = this.newsList.slice(start, end);
    this.checkNavigation();
  }

  previous() {
    this.newsIndex -= this.itemsPerPage;
    this.renderNews();
  }

  next() {
    this.newsIndex += this.itemsPerPage;
    this.renderNews();

    if (this.isLast) {
      this.load();
    }
  }

  checkNavigation() {
    this.isFirst = this.newsIndex < this.itemsPerPage;
    this.isLast = this.newsIndex >= this.newsList.length - this.itemsPerPage;
  }

  getStockLink(news: any): string {
    return `/ticker/${news.ticker}/${news.prompt_id}`;
  }

  onStartDateChange() {
    this.load(true);
  }

  onEndDateChange() {
    this.load(true);
  }

  onIndustryChange(industry: string) {
    // Implement industry filter logic
  }

  onSearch(value: any) {
    // Handle search input
    console.log(value)
  }

  toggle() {
    this.searchSectionOpened = !this.searchSectionOpened;
  }

  getTitle() {
    return this.newsNavigationService.getTitle();
  }

  private setMaxLength() {
    if (this.isMobileDevice()) {
      this.maxLength = 1000;
      return;
    }

    this.maxLength = window.screen.height / 2;
  }

  private isMobileDevice(): boolean {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }
}
