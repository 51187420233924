<div class="metrics" (click)="toggle()">
  <mat-icon>{{ open ? 'expand_less' : 'expand_more' }}</mat-icon>
  <h2>Financial Metrics</h2>
</div>
<mat-list role="list" *ngIf="open && (data$ | async); let data">
  <mat-list-item role="listitem" class="left">
    <h3 mat-line matTooltip="Market Capitalization">Market Capitalization</h3>
    <p mat-line>${{data.MarketCapitalization | safeNumber:'-'}} USD</p>
  </mat-list-item>
  <mat-list-item role="listitem">
    <h3 mat-line matTooltip="Analyst Target Price">Analyst Target Price</h3>
    <p mat-line>${{data.AnalystTargetPrice}} USD</p>
  </mat-list-item>
  <mat-list-item role="listitem">
    <h3 mat-line matTooltip="PE Ratio (Price to Earnings)">PE Ratio (Price to Earnings)</h3>
    <p mat-line>{{data.PERatio}}</p>
  </mat-list-item>
  <mat-list-item role="listitem">
    <h3 mat-line matTooltip="Beta">Beta</h3>
    <p mat-line>{{data.Beta}}</p>
  </mat-list-item>
  <mat-list-item role="listitem" class="left">
    <h3 mat-line matTooltip="Dividend Yield">Dividend Yield</h3>
    <p mat-line>{{data.DividendYield | safePercent:'-'}}</p>
  </mat-list-item>
  <mat-list-item role="listitem">
    <h3 mat-line matTooltip="EPS (Earnings Per Share)">EPS (Earnings Per Share)</h3>
    <p mat-line>${{data.EPS}} USD</p>
  </mat-list-item>
  <mat-list-item role="listitem">
    <h3 mat-line matTooltip="Book Value per Share">Book Value per Share</h3>
    <p mat-line>${{data.BookValue}} USD</p>
  </mat-list-item>
  <mat-list-item role="listitem">
    <h3 mat-line matTooltip="Revenue TTM (Trailing Twelve Months)">Revenue TTM (Trailing Twelve Months)</h3>
    <p mat-line>${{data.RevenueTTM  | safeNumber:'-'}} USD</p>
  </mat-list-item>
  <mat-list-item role="listitem" class="left">
    <h3 mat-line matTooltip="Profit Margin">Profit Margin</h3>
    <p mat-line>{{data.ProfitMargin | safePercent:'-'}}</p>
  </mat-list-item>
  <mat-list-item role="listitem">
    <h3 mat-line matTooltip="Quarterly Revenue Growth YoY">Quarterly Revenue Growth YoY</h3>
    <p mat-line>{{data.QuarterlyRevenueGrowthYOY | safePercent:'-'}}</p>
  </mat-list-item>
  <mat-list-item role="listitem">
    <h3 mat-line matTooltip="Return on Equity TTM">Return on Equity TTM</h3>
    <p mat-line>{{data.ReturnOnEquityTTM | safePercent:'-'}}</p>
  </mat-list-item>
  <mat-list-item role="listitem">
    <h3 mat-line matTooltip="52-Week High">52-Week High</h3>
    <p mat-line>{{data['52WeekHigh']}} USD</p>
  </mat-list-item>
  <mat-list-item role="listitem" class="left">
    <h3 mat-line matTooltip="52-Week Low">52-Week Low</h3>
    <p mat-line>{{data['52WeekLow']}} USD</p>
  </mat-list-item>
  <mat-list-item role="listitem">
    <h3 mat-line matTooltip="Forward PE">Forward PE</h3>
    <p mat-line>{{data.ForwardPE}}</p>
  </mat-list-item>
  <mat-list-item role="listitem">
    <h3 mat-line matTooltip="EBITDA">EBITDA</h3>
    <p mat-line>{{data.EBITDA | safeNumber:'-'}} USD</p>
  </mat-list-item>
  <mat-list-item role="listitem">
    <h3 mat-line matTooltip="Gross Profit TTM">Gross Profit TTM</h3>
    <p mat-line>{{data.GrossProfitTTM | safeNumber:'-'}} USD</p>
  </mat-list-item>
  <mat-list-item role="listitem" class="left description">
    <h3 mat-line matTooltip="Description">Description</h3>
  </mat-list-item>
</mat-list>
<p *ngIf="open && (data$ | async); let data" class="description">{{data.Description}}</p>
