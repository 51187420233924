<div class="left">

    <h1>Your Co-Pilot for Financial Analysis</h1>

    <div class="item-wrapper">
        <h3>Summaries</h3>
        <p>
            Tailored to your investment universe
        </p>
    </div>

    <div class="item-wrapper">
        <h3>Data</h3>
        <p>
            Integration with your providers
        </p>
    </div>

    <div class="item-wrapper">
        <h3>Back-test</h3>
        <p>
            Historical performance of your prompts
        </p>
    </div>

    <div class="item-wrapper">
        <h3>Fine-Tune</h3>
        <p>
            LLMs on your trade history
        </p>
    </div>

</div>
<div class="right">
    <img src="/assets/build.png">
</div>

<div class="content-wrapper">

    <div class="content-section reverse">
        <div class="text">
            <h2>Concise Market Insights</h2>
            <p>
                Receive distilled, easy-to-digest summaries tailored to your specific investment universe. Our tool
                scans the latest market movements and news, filtering and summarizing content to keep you informed
                without the noise.
            </p>
        </div>
        <div class="image">
            <div class="image-wrapper">
                <img src="/assets/tool.jpeg" class="circular-gradient">
            </div>
        </div>
    </div>

    <div class="content-section">
        <div class="text">
            <h2>Seamless Data Integration</h2>
            <p>
                Connect effortlessly with your existing data providers. Our platform is designed for smooth integration,
                ensuring you can analyze markets using familiar, reliable sources to power your investment decisions.
            </p>
        </div>
        <div class="image">
            <img src="/assets/faq.png">
        </div>
    </div>

    <div class="content-section reverse">
        <div class="text">
            <h2>Customize LLMs for Your Trading</h2>
            <p>
                Train large language models (LLMs) using your unique trade history. By fine-tuning on past strategies
                and results, our system enhances precision and adapts to your investment style for more personalized
                news summaries.
            </p>
        </div>
        <div class="image">
            <img src="/assets/platform.jpeg" class="circular-gradient">
        </div>
    </div>

    <div class="content-section">
        <div class="text">
            <h2>Validate with Historical Data</h2>
            <p>
                Evaluate the performance of your prompts using historical data. Our back-testing tool provides insight
                into how strategies would have played out, helping you optimize and refine before committing to live
                trades.
            </p>
        </div>
        <div class="image">
            <img src="/assets/performance.png">
        </div>
    </div>
</div>

<div class="support-wrapper">
    <div class="left">

        <h1>Support</h1>
    
        <div class="item-wrapper">
            <h3>Critical Issues</h3>
            <p>
                Resolved as soon as possible
            </p>
        </div>
    
        <div class="item-wrapper">
            <h3>Customization</h3>
            <p>
                On-demand for your needs
            </p>
        </div>
    
        <div class="item-wrapper">
            <h3>Training</h3>
            <p>
                So you can make the most of it
            </p>
        </div>
    
    </div>
    <div class="right">
        <img src="/assets/support.png">
    </div>
</div>

<h1 class="faq-title">FAQ</h1>

<div class="faq-container">

    <div class="tile">
        <div class="icon"><img src="/assets/icon-cloud.svg"></div>
        <h3>Data Security</h3>
        <p class="description">GPT Analyst is deployed on the Google Cloud and follows audited security best practices to ensure your data is safe</p>
    </div>
    <div class="tile">
        <div class="icon"><img src="/assets/icon-group.svg"></div>
        <h3>Private Deployment</h3>
        <p class="description">GPT Analyst can be deployed to your private Google Cloud subscription with the region and security settings of your choice</p>
    </div>
    <div class="tile">
        <div class="icon"><img src="/assets/icon-app.svg"></div>
        <h3>Data Sources</h3>
        <p class="description">You are responsible to bring the data you want to use and pay for the necessary subscriptions</p>
    </div>
</div>
