import { Component, OnDestroy } from '@angular/core';
import { User } from '@angular/fire/auth';
import { Store } from '@ngxs/store';
import { UserState } from '../store/user/user.state';
import { Subscription } from '../store/user/subscription.model';
import { UserProfile } from '../store/user/user-profile.model';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  subscription: Subscription | null = null;
  profile: UserProfile | null = null;

  user: User | null = null;
  isAdmin: boolean = false

  constructor(store: Store) {
    store.select(UserState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(state => {
        this.user = state.user;
        this.profile = state.profile;
        this.subscription = state.subscription;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
