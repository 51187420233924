import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { PredictionSelectors } from '../../../store/prediction/prediction.selector';
import { PredictionBack, PredictionNext } from '../../../store/prediction/prediction.state';
import { DataGenerator, NewsSummaryStatus } from '../../../store/data-generator/data-generator.model';
import { Prediction } from '../../../store/prediction/prediction.model';
import { DeleteDataGenerator, RetryDataGenerator } from '../../../store/data-generator/data-generator.state';
import { UserState } from '../../../store/user/user.state';
import { map } from 'rxjs';

@Component({
  selector: 'app-data-generator-detail',
  templateUrl: './data-generator-detail.component.html',
  styleUrls: ['./data-generator-detail.component.scss'],
})
export class DataGeneratorDetailComponent implements OnInit {
  @Input() generator!: DataGenerator;
  @Output() clone = new EventEmitter<DataGenerator>();

  isAdmin$ = this.store.select(UserState.isAdmin);
  isOwner$ = this.isAdmin$.pipe(
    map((isAdmin) => {
      return isAdmin || this.generator.isOwner;
    })
  );

  get id() {
    return this.generator.news_summariser_id;
  }

  get currentStatus() {
    return this.generator?.status ?? NewsSummaryStatus.unavailable;
  }

  get statusTooltip() {
    return this.getStatusTooltip(this.currentStatus);
  }

  get cost() {
    const p = this.currentPrediction;
    const tokens = (p?.input_tokens ?? 0) + (p?.output_tokens ?? 0);
    const words = (tokens * 0.75).toFixed(0);
    const workHours = (60 * tokens / 2000).toFixed(0);

    const g = this.generator;
    const cumulativeTokens = (g.inputTokens ?? 0) + (g.outputTokens ?? 0);
    const cumulativeWords = (cumulativeTokens * 0.75).toFixed(0);
    const cumulativeHours = (cumulativeTokens / 2000).toFixed(0);
    const cumulativeText = `${cumulativeWords} words ~ ${cumulativeHours}h since start`;

    return `Processed ${words} words ~ ${workHours}min of work (${cumulativeText})`;
  }

  get costToolTip() {
    const isAdmin = this.store.selectSnapshot(UserState.isAdmin);
    if (!isAdmin) {
      return 'This is an estimate of the cost of the prediction. The actual cost may vary.';
    }

    const p = this.currentPrediction;
    const cost = (p?.cost ?? 0).toFixed(3);
    return `This is an estimate. ${cost} USD.`;
  }

  currentPrediction!: Prediction | null;
  isFirst: boolean = false;
  isLast: boolean = false;
  isLoading: boolean = false;
  statusEnum = NewsSummaryStatus;

  constructor(private store: Store) {}

  ngOnInit(): void {
    const id = this.generator.news_summariser_id;

    this.store
      .select(PredictionSelectors.selectedForGenerator(id))
      .subscribe((prediction) => (this.currentPrediction = prediction));

    this.store
      .select(PredictionSelectors.isFirstPrediction(id))
      .subscribe((isFirst) => (this.isFirst = isFirst));

    this.store
      .select(PredictionSelectors.isLastPrediction(id))
      .subscribe((isLast) => (this.isLast = isLast));

    this.store
      .select(PredictionSelectors.isLoading(id))
      .subscribe((isLoading) => (this.isLoading = isLoading));
  }

  previous() {
    this.store.dispatch(new PredictionBack(this.id));
  }

  next() {
    this.store.dispatch(new PredictionNext(this.id));
  }

  deleteGenerator() {
    this.store.dispatch(new DeleteDataGenerator(this.id));
  }

  cloneGenerator() {
    this.clone.emit(this.generator);
  }

  retryGenerator() {
    this.store.dispatch(new RetryDataGenerator(this.generator.news_summariser_id));
  }

  private getStatusTooltip(status: NewsSummaryStatus): string {
    switch (status) {
      case NewsSummaryStatus.queued:
        return 'Prediction queued';
      case NewsSummaryStatus.running:
        return 'Prediction is running';
      case NewsSummaryStatus.interrupted:
        return 'Prediction interrupted';
      case NewsSummaryStatus.ready:
        return 'Prediction ready';
      case NewsSummaryStatus.error:
        return 'Prediction encountered an error';
      default:
        return 'Status unavailable';
    }
  }
}
