<div class="profile-container">
  <div class="profile-header">
    <div class="avatar">
      <img [src]="user?.photoURL" alt="Avatar">
    </div>
    <div class="profile-info">
      <div class="name">{{ user?.displayName || 'Unknown Name' }}</div>
      <div class="email">{{ user?.email || 'Unknown Email' }}</div>
      <div class="subscription">Subscription: {{ subscription?.name || 'N/A' }}</div>
    </div>
  </div>
  <h2>Connections</h2>
  <a href="#">Connect GPT Analyst with Alpaca</a>
  <!-- Needs to be implemented with OAuth flow -->

  <h2>Usage</h2>
  <div class="profile-details">
    <table>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Maximum allowed</th>
          <th>Current usage</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Backtests per day</td>
          <td>{{ subscription?.maxBacktests }}</td>
          <td>{{ profile?.backtests }}</td>
        </tr>
        <tr>
          <td>Active backtests</td>
          <td>{{ subscription?.maxActiveBacktests }}</td>
          <td>{{ profile?.active_backtests }}</td>
        </tr>
        <tr>
          <td>Previews per day</td>
          <td>{{ subscription?.maxPreviews }}</td>
          <td>{{ profile?.previews }}</td>
        </tr>
        <tr>
          <td>Prompts</td>
          <td>{{ subscription?.maxPrompts }}</td>
          <td>{{ profile?.prompts }}</td>
        </tr>
        <tr>
          <td>Active prompts</td>
          <td>{{ subscription?.maxActive }}</td>
          <td>{{ profile?.active_runs }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <app-user-prompts></app-user-prompts>
</div>

