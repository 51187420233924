import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
  AfterViewInit
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GaAutocompleteDropdownComponent } from './ga-autocomplete-dropdown.component';
import { GaAutocompleteDialogComponent } from './ga-autocomplete-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[gaAutocomplete]'
})
export class GaAutocompleteDirective implements AfterViewInit {
  @Input() autocompleteOptions: any[] = [];
  private dropdownRef: ComponentRef<GaAutocompleteDropdownComponent> | null = null;
  private backdropElement: HTMLElement | null = null;
  private clickableElement: HTMLElement | null = null;
  private triggerSymbol: string = '@';
  private currentTriggerPosition: number | null = null;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private vcr: ViewContainerRef,
    private cfr: ComponentFactoryResolver,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer)
  {}

  ngAfterViewInit(): void {
    // Query the backdrop element that is inside the parent of this textarea
    const parent = this.el.nativeElement.parentNode;
    this.backdropElement = parent.querySelector('.backdrop');
    this.clickableElement = parent.querySelector('.click-overlay');
  }

  @HostListener('input', ['$event.target'])
  onInput(inputElement: HTMLTextAreaElement) {
    const text = inputElement.value;
    const cursorPosition = inputElement.selectionStart;

    // Check for trigger symbol before showing autocomplete
    const triggerIndex = text.lastIndexOf(this.triggerSymbol, cursorPosition - 1);
    if (triggerIndex !== -1 && triggerIndex < cursorPosition) {
      this.currentTriggerPosition = triggerIndex;
      const triggerText = text.substring(triggerIndex + 1, cursorPosition);
      this.showAutocomplete(triggerText, inputElement);
    } else {
      this.hideAutocomplete();
    }

    this.highlightKeywords(text);
  }

  showAutocomplete(text: string, inputElement: HTMLTextAreaElement) {
    if (!this.dropdownRef) {
      const factory = this.cfr.resolveComponentFactory(GaAutocompleteDropdownComponent);
      this.dropdownRef = this.vcr.createComponent(factory);
    }

    // Calculate cursor position using line height and font size
    const fontSize = parseInt(window.getComputedStyle(inputElement).fontSize, 10);
    const lineHeight = parseInt(window.getComputedStyle(inputElement).lineHeight, 10);
    const cursorPosition = inputElement.selectionStart;
    const textBeforeCursor = inputElement.value.substring(0, cursorPosition);
    const lines = textBeforeCursor.split('\n');
    const currentLine = lines[lines.length - 1];
    const leftOffset = currentLine.length * fontSize;

    // Adjust dropdown position relative to the cursor
    this.dropdownRef.instance.position = {      
      top: lines.length * lineHeight,
      left: leftOffset
    };

    this.dropdownRef.instance.options = this.filterOptions(text);
    this.dropdownRef.instance.optionSelected.subscribe((selectedOption: string) => {
      this.addKeywordToText(selectedOption, inputElement);
    });
  }

  filterOptions(text: string): string[] {
    return this.autocompleteOptions.filter(option => option.toLowerCase().includes(text.toLowerCase()));
  }

  addKeywordToText(keyword: string, inputElement: HTMLTextAreaElement) {
    const text = inputElement.value;
    if (this.currentTriggerPosition !== null) {
      const beforeTrigger = text.substring(0, this.currentTriggerPosition);
      const afterTrigger = text.substring(inputElement.selectionStart);
      const newText = `${beforeTrigger}${this.triggerSymbol}${keyword} ${afterTrigger}`;
      inputElement.value = newText;
      this.currentTriggerPosition = null;

      // Move cursor to the end of the inserted keyword
      inputElement.selectionStart = inputElement.selectionEnd = beforeTrigger.length + this.triggerSymbol.length + keyword.length + 1;

      this.renderer.setProperty(this.backdropElement, 'innerHTML', newText);
      this.hideAutocomplete();
    }

    this.highlightKeywords(inputElement.value);
  }

  @HostListener('document:click', ['$event.target'])
  hideAutocomplete(target?: HTMLElement) {
    if (target?.classList.contains('click-overlay-keyword')) {
      const keyword = target.getAttribute('data-keyword');
      this.onKeywordClick(keyword);
      return;
    }

    if (this.dropdownRef && (!target || !this.el.nativeElement.contains(target))) {
      this.dropdownRef.destroy();
      this.dropdownRef = null;
    }
  }

  highlightKeywords(text: string) {
    text = text.replaceAll(' ', '&nbsp;');
    text = text.replaceAll('\n', '<br>');
    const search = this.autocompleteOptions.map(o => `@${o}`).join('|');
    const highlighted = text.replace(
      new RegExp(`(${search})`, 'gi'),
      `<span style="position: relative; background: yellow; opacity: 0.4; left: 0; top: 0">$1</span>`
    );
    this.renderer.setProperty(this.backdropElement, 'innerHTML', highlighted);

    const clickable = text.replace(
      new RegExp(`(${search})`, 'gi'),
      `<span class="click-overlay-keyword"
             data-keyword="$1"
             style="position: relative; cursor: pointer; opacity: 0; left: 0; top: 0">$1</span>`
    );
    this.renderer.setProperty(this.clickableElement, 'innerHTML', clickable);
  }

  onKeywordClick(keyword: string | null) {
    const dialogRef = this.dialog.open(GaAutocompleteDialogComponent, {
      data: { name: keyword }
    });

    dialogRef.afterClosed().subscribe(result => {
      // Handle result
    });
  }
}
