import { Component, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { collection, Firestore, onSnapshot, query, Unsubscribe } from '@angular/fire/firestore';
import { Subject } from 'rxjs';

@Component({
  selector: 'generator-overview',
  templateUrl: './generator-overview.component.html',
  styleUrls: ['./generator-overview.component.scss'],
})
export class GeneratorOverviewComponent implements AfterViewInit, OnDestroy {
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = [
    'symbol',
    'name',
    'status',
    'ownerId',
    'runFreq',
    'started_at',
    'cost'
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  private destroy$ = new Subject<void>();
  private dataGeneratorListener: Unsubscribe | undefined;

  constructor(private db: Firestore) {}

  ngAfterViewInit() {
    const q = query(collection(this.db, 'data_generators'));
    this.dataGeneratorListener = onSnapshot(q, (querySnapshot) => {
      const result: any[] = [];
      querySnapshot.forEach((doc) => {
        const data: any = doc.data();
        let tags = data.tags ?? [];
        tags = tags.map((tag: string) => tag.trim().replace('symbol:', ''));

        result.push({
          ...data,
          started_at: new Date(data.startedAt).toLocaleString(),
          symbol: tags.join(', '),
          cost: data.cost?.toFixed(3) ?? '-'
        });
      });
      this.dataSource = new MatTableDataSource<any>(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.dataGeneratorListener?.();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
