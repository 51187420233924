import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from '../../../components/confirmation-dialog/confirmation-dialog.component';
import { Prompt } from '../../../model/prompt.model';
import { SymbolService } from '../../../services/symbol.service';
import { Store } from '@ngxs/store';
import { DataGenerator } from '../../../store/data-generator/data-generator.model';
import { CreateDataGenerator } from '../../../store/data-generator/data-generator.state';
import { CronOptions } from 'ngx-cron-editor';


@Component({
  selector: 'app-data-generator-editing',
  templateUrl: './data-generator-editing.component.html',
  styleUrl: './data-generator-editing.component.scss',
})
export class DataGeneratorEditingComponent implements OnInit, OnDestroy {
  // Ten most common timezones
  timezones: string[] = [];

  generator: DataGenerator;

  public cronOptions: CronOptions = {
    defaultTime: "00:00",

    hideMinutesTab: false,
    hideHourlyTab: false,
    hideDailyTab: false,
    hideWeeklyTab: false,
    hideMonthlyTab: false,
    hideYearlyTab: true,
    hideAdvancedTab: false,
    hideSpecificWeekDayTab: false,
    hideSpecificMonthWeekTab : false,

    use24HourTime: true,
    hideSeconds: true,

    cronFlavor: "standard" //standard or quartz
  };

  selectedTabIndex = 0;

  private destroy$ = new Subject<void>();
  public hasChanges: boolean = false;

  constructor(
    private store: Store,
    private symbolService: SymbolService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DataGeneratorEditingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { generator: DataGenerator }) {
      this.generator = data.generator;
    }

  ngOnInit(): void {
    this.symbolService.symbol$
      .pipe(takeUntil(this.destroy$))
      .subscribe((symbol) => {
        this.generator.symbol = symbol;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async save() {
    await this.store.dispatch(new CreateDataGenerator(this.generator));
    this.dialogRef.close();
  }

  insertInput(item: any) {
    this.generator.prompt.template_body += `${item.name}: \n${item.examples[0]}`;
  }

  handleCancel(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { message: 'You have unsaved changes. Do you want to exit without saving?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === false) return;
      this.dialogRef.close();
    });
  }

}
