import { Component } from '@angular/core';
import { GaAutocompleteDialogComponent } from './ga-autocomplete-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'ga-autocomplete',
  templateUrl: './ga-autocomplete.component.html',
  styleUrls: ['./ga-autocomplete.component.scss']
})
export class GaAutocompleteComponent {
  public highlightedText: string = '';
  public clickableText: string = '';

  constructor(private dialog: MatDialog) {}

  onKeywordClick(keyword: string) {
    console.log('Keyword clicked:', keyword);
    const dialogRef = this.dialog.open(GaAutocompleteDialogComponent, {
      data: { name: keyword }
    });

    dialogRef.afterClosed().subscribe(result => {
      // Handle result
    });
  }
}
